import { Card } from '@progress/kendo-react-layout';
import { VoidFunctionComponent, MouseEvent, FunctionComponent } from 'react';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import './LaunchCardTheme.scss';

interface ILaunchCardProps
{
    /**
     * Card title. Mandatory.
     */
    title: string;
    /**
     * The transaction id. Mandatory.
     */
    transactionId: string;
    /**
     * Click event handler. Optional.
     * If implemented, ignores the default handler which uses the `transactionId`
     * to navigate to that transaction.
     */
    onClick?: (event: MouseEvent<HTMLDivElement>) => void | Promise<void>;
}

/**
 * Card component for launching actions.
 * @param {ILaunchCardProps} props
 * @returns LaunchCard
 */
export const LaunchCard: VoidFunctionComponent<ILaunchCardProps> = ({
    title,
    transactionId,
    onClick
}) =>
{
    const navigate = useNavigate();

    const _onClick = onClick ?? ((event: MouseEvent<HTMLDivElement>) =>
    {
        const url = `/${transactionId}/`;

        if (event.ctrlKey)
        {
            window.open(url);
        }
        else
        {
            navigate(url);
        }
    });

    const functionKey = transactionId.charAt(0);

    return (
        <Card className="launchCard"
            title={title}
            onClick={_onClick}
            {...functionKey && { 'fmt': functionKey }}>
            <div className="transactionName">
                {title}
            </div>
            <div className="transactionId">
                <span>{transactionId}</span>
            </div>
        </Card>
    );
};

LaunchCard.displayName = 'LaunchCard';

interface ICardDeckProps
{
    className?: string;
}

export const CardDeck: FunctionComponent<ICardDeckProps> = ({ className, children }) =>
    (<div className={`k-card-deck ${className}`}>
        { children }
    </div>);

CardDeck.displayName = 'CardDeck';