import _ from 'lodash';

import { ArrayFieldNames, DeepPartialChanges, SimpleValue } from '../TypeUtils';

/**
 * Adds to the element and to all properties of the element that are objects (recursively) the property:
 * `columnsWithPendingChangesNames` a list of field names of `T` in pascal case
 *
 * Note: this is needed for the backend in order to work properly with a `DeepPartial<T>`
 *
 * @param element a partial element of `T`
 * @returns {DeepPartialChanges<T>}
 */
export const WithPartialChanges = function<T>(element: T): DeepPartialChanges<T>
{
    const result = _.cloneDeep(element);
    const columnsWithPendingChangesNames : ArrayFieldNames<T> = [];
    for (const key in result)
    {
        const value = result[key];
        if (!isSimpleValue(value))
        {
            result[key] = WithPartialChanges(value) as any;
        }
        const keyPascalCase = camelCase2PascalCase(key);
        columnsWithPendingChangesNames.push(keyPascalCase);
    }
    return {
        ...result,
        columnsWithPendingChangesNames
    } as DeepPartialChanges<T>;
};

/**
 * Transform the camelCase to PascalCase
 * @param str - string in camelCase
 * @returns a new string in PascalCase
 */
const camelCase2PascalCase = <T extends string>(str: T): Capitalize<T> =>
{
    if (!str)
    {
        return str as Capitalize<T>;
    }
    return str.charAt(0).toUpperCase() + str.slice(1) as Capitalize<T>;
};

/**
 * Check if the value is an object or not.
 * @param value
 * @returns `true` if is a `SimpleValue`
 */
const isSimpleValue = (value: any) : value is SimpleValue  =>
{
    if (value instanceof Object && !Array.isArray(value) && !_.isDate(value))
    {
        return false;
    }
    return true;
};