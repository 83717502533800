import { forEach, groupBy, sortBy, sum } from 'lodash';
import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { DescargaConductorDTO, GraficaDescargaConductorDTO } from './I000000DTO';
import { DonutChartWidget } from './Widgets/DonutChartWidgetComponent';

interface IConductoresDescargaChartProps
{
    conductores: Array<DescargaConductorDTO>;
}

export const ConductoresDescargaChart: VoidFunctionComponent<IConductoresDescargaChartProps> = ({ conductores }) =>
{
    const { t } = useTranslation(['/FMT/I/I00/I000000/I000000'], { useSuspense: false });
    const navigate = useNavigate();

    const datosGrafica: Array<GraficaDescargaConductorDTO> = [];

    forEach(groupBy(sortBy(conductores, e => e.pendienteDescarga), (e) => e.pendienteDescarga), (elementos, pendienteDescarga) =>
    {
        datosGrafica.push({
            denominacionTipo: pendienteDescarga === 'true' ?
                t('widgetConductores.pendienteDescarga')
                : t('widgetConductores.descargados'),
            numeroElementos: elementos.length,
            color: pendienteDescarga === 'true' ?
                '#C7001E'
                : '#53C700'
        });
    });

    const donutCenterRender = () => (
        <>
            <h5 className='totalConductores'>
                {sum(datosGrafica.map(e => e.numeroElementos))}
            </h5>
            <p>Total</p>
        </>
    );

    return (
        <DonutChartWidget<GraficaDescargaConductorDTO>
            title={t('widgetConductores.titulo')}
            className='conductores'
            data={datosGrafica}
            categoryField='denominacionTipo'
            field='numeroElementos'
            donutCenterRender={donutCenterRender}
            onClick={() => navigate('/C000000')}>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetConductores.pendienteDescarga'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetConductores.pendienteDescarga')} </p>
        </DonutChartWidget>
    );
};
ConductoresDescargaChart.displayName = 'ConductoresDescargaChart';
