import { createContext, forwardRef, FunctionComponent, ReactNode, useImperativeHandle, useRef, useState } from 'react';

import { useConstant } from '../Hooks/ConstantHook';
import { useIsMounted } from '../Hooks/MountHook';

import { ILoader, ILoaderEngine } from './ILoader';
import { isLoaderEngineRef } from './LoaderHook';

export const LoaderSetterContext = createContext<ILoader>({ showLoader: () => {}, hideLoader: () => {} });
export const LoaderGetterContext = createContext<boolean>(false);

/**
 * The Loader context.
 *
 * Using the hook `const { showLoader, hideLoader } = useLoader();` any child is capable to change the loader status.
 * Using the hook `const isLoading = useLoading();` any child is subscribed to the loader status (loading or not loading) and is rerendered when
 * the loader status change.
 *
 * This provider have the property engine.
 * Using the hook `const loaderEngine = useLoaderEngine()` any parent componente can control the loader from outside the
 * LoaderProvider
 */
export const LoaderProvider: FunctionComponent<{ engine?: ILoaderEngine }> = ({
    engine,
    children
}) =>
{
    if (engine && !isLoaderEngineRef(engine))
    {
        throw new Error('❌ the engine props is not a valid `ILoaderEngine`, use the hook `useLoaderEngine` to create it!');
    }
    return <LoaderProviderRef ref={engine?.__ref}>{ children }</LoaderProviderRef>;
};

const LoaderProviderRef = forwardRef<ILoaderEngine, { children: ReactNode}>((props, ref) =>
{
    const counterRef = useRef<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);

    const isMounted = useIsMounted();

    const loaderSetter = useConstant(() =>
    {
        return {
            showLoader: () =>
            {
                counterRef.current += 1;
                setLoading(true);
            },
            hideLoader: () =>
            {
                // Update the value
                counterRef.current -= 1;
                if (counterRef.current === 0 && isMounted())
                {
                    setLoading(false);
                }
            }
        };
    });

    useImperativeHandle(ref, () =>
    {
        return {
            ...loaderSetter,
            isLoading
        };
    });

    return (
        // Double context to avoid rerenders =>
        // https://medium.com/@bhavyasaggi/how-did-i-re-render-sharing-state-through-react-context-f271d5890a7b
        // https://codesandbox.io/s/dual-context-props-zwk6d?from-embed=&file=/src/ContextProvider.js
        // https://codesandbox.io/s/dual-context-props-forked-f7mpjc <= test
        <LoaderSetterContext.Provider value={loaderSetter}>
            <LoaderGetterContext.Provider value={isLoading}>
                { props.children }
            </LoaderGetterContext.Provider>
        </LoaderSetterContext.Provider>
    );
});