import { FunctionComponent, useState, useRef } from 'react';
import { WithTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { AccessResultDTO, AuthDTO } from 'Services/Authentication/IAuthenticationService';

import { FallbackNavSidebar, FallbackNavTopBar } from 'Components/Core/Fallback/FallbackLayoutComponent';
import { useGet } from 'Components/Core/Hooks/GetHook';
import { DivColumn, DivRow } from 'Components/Core/Layout/DivComponent';
import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { ModifyPasswordForm } from './ModifyPasswordFormComponent';
import tmsLogo from './TMS_Logo.png';
import { TwoFactorAuthForm } from './TwoFactorAuthFormComponent';
import { UserPassForm } from './UserPassFormComponent';

import './I010000Theme.scss';

const transactionId = 'I010000';

/**
 * Pantalla de login a la aplicación
 * @author Diego Crenes Carrera, diego.crenes[at]airgrup.com
 * @author Javier Arnáiz González, javier.arnaiz[at]airgrup.com
 */
export const I010000 : FunctionComponent<WithTranslation> = (translation: WithTranslation) =>
{
    // #region Hooks
    const navigate = useNavigate();
    const location = useLocation();
    // #endregion

    // #region Autenticación en dos pasos
    const [token, setToken] = useState<string | null>(null);
    const [modificarPassword, setModificarPassword] = useState(false);
    const [enable2FA, setEnable2FA] = useState(false);

    const on2FAUserPassForm = (accessResult: AccessResultDTO) =>
    {
        setEnable2FA(accessResult.enable2FA);
        setToken(accessResult.token);
    };
    // #endregion

    // #region Cambio de contraseña
    const onModifyPassword = (accessResult: AccessResultDTO) =>
    {
        setEnable2FA(accessResult.enable2FA);
        setToken(accessResult.token);

        setModificarPassword(true);
    };
    // #endregion

    // #region Eventos de submit
    const haciendoSubmitRef = useRef<boolean>(false);

    const onBeforeSubmit = () =>
    {
        haciendoSubmitRef.current = true;
    };

    const onAfterSubmit = (success: boolean) =>
    {
        if (!success)
        {
            haciendoSubmitRef.current = false;
        }
    };
    // #endregion
    // #region Exito en login
    const [exito, setExito] = useState<boolean>(false);
    const divTransaccionRef = useRef<HTMLDivElement>(null);

    const onSuccess = (usuario: AuthDTO) =>
    {
        setEnable2FA(usuario.enable2FA);
        setModificarPassword(usuario.modifyPassword);

        if (!usuario.enable2FA && !usuario.modifyPassword)
        {
            setExito(true);
        }
    };

    useGet(() =>
    {
        // #region Evento de transición de login a 2FA
        const _divTransaccion = divTransaccionRef.current;

        const onTransitionEndDivUserPass = (ev: TransitionEvent) =>
        {
            const eventTarget = ev.target as HTMLDivElement;

            // Navegamos a Home una vez que haya terminado
            // la animación de transición llamada 'sucess'
            if (eventTarget === _divTransaccion
                && eventTarget.classList.contains('success'))
            {
                const state = location.state as { from: Location } | undefined;

                navigate(state?.from?.pathname || '/');
            }
        };

        _divTransaccion?.addEventListener('transitionend', onTransitionEndDivUserPass);
        // #endregion

        // Eliminamos los eventos cuando se desmonte el componente
        return () =>
        {
            _divTransaccion?.removeEventListener('transitionend', onTransitionEndDivUserPass);
        };
    });
    // #endregion

    return (
        <div className="wrapper">
            <div className="loginContent">
                <header className={`loginHeader ${exito ? 'success' : ''}`}>
                    <FallbackNavTopBar />
                </header>
                <main {...exito && { className: 'success' }}>
                    <FallbackNavSidebar />
                    <div id={transactionId} ref={divTransaccionRef} {...exito && { className: 'success' }}>
                        <h1 className="title">
                            <span className='acronym'>TMS</span>
                            <span className='name'>Tachograph Management System</span>
                        </h1>
                        <br></br>
                        <img src={tmsLogo} alt="TMS Logo" className='d-none d-sm-block' style={{ height:'60px' }}/>
                        <div className="beta">BETA</div>
                        <DivRow id="FormContainer">
                            <DivColumn className={`userPass ${enable2FA || modificarPassword ? 'prev' : ''}`}>
                                <UserPassForm onBeforeSubmit={onBeforeSubmit} onAfterSubmit={onAfterSubmit} on2FA={on2FAUserPassForm} onSuccess={onSuccess} onModifyPassword={onModifyPassword}/>
                            </DivColumn>
                            <DivColumn className={`twoFactor ${!token && !modificarPassword ? 'next' : ''}`}>
                                {enable2FA  &&
                                    <TwoFactorAuthForm token={token} onBeforeSubmit={onBeforeSubmit} onAfterSubmit={onAfterSubmit} onSuccess={onSuccess} />
                                }
                                {!enable2FA && modificarPassword &&
                                    <ModifyPasswordForm token={token} onBeforeSubmit={onBeforeSubmit} onAfterSubmit={onAfterSubmit} onSuccess={onSuccess} />
                                }
                            </DivColumn>
                        </DivRow>
                    </div>
                </main>
            </div>
        </div>
    );
};

I010000.displayName = transactionId;