import { DependencyList, MutableRefObject, useEffect } from 'react';

/**
 * Execute the read function when the component is loaded for the first time.
 *
 * Use the deps params to allow the read function to execute again when the deps change.
 * @param readFunction the callback with a mount param to indicate if the current component is already mounted or not.
 * @param deps, default to `[]`
 */
export const useGet = (readFunction: (mount: MutableRefObject<boolean>) => Promise<void | (() => void)> | void | (() => void), deps: DependencyList = []) =>
{
    useEffect(() =>
    {
        const mount: MutableRefObject<boolean> = { current: true };

        const cleanUpPromise = readFunction(mount);

        return () =>
        {
            if (cleanUpPromise)
            {
                Promise.resolve(cleanUpPromise)
                    .then((cleanUpFunction) =>
                    {
                        if (cleanUpFunction)
                        {
                            cleanUpFunction();
                        }
                    });
            }

            mount.current = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};