import { MutableRefObject, useContext, useRef } from 'react';

import { ILoader, ILoaderEngine } from './ILoader';
import {  LoaderGetterContext, LoaderSetterContext } from './LoaderProvider';

/**
 * Get the loader status.
 * @returns `true` if is loading, `false` otherwise
 */
export const useLoading = (): boolean =>
{
    return useContext(LoaderGetterContext);
};

/**
 * Allow to show or hide the loader.
 *
 * Once `showLoader()` is called it is the developer's responsibility to call `hideLoader()` or the loading will be displayed indefinitely.
 * @returns {ILoader}
 */
export const useLoader = (): ILoader =>
{
    return useContext(LoaderSetterContext);
};

/**
 * Allow the parent component of the `<LoaderProvider />` to create the engine and use the loader engine
 * from the parent without rerendering it.
 *
 * Usage:
 *
 * ```tsx
 * // Allow to use loaderEngine.showLoader(); and loaderEngine.hideLoader();
 * const loaderEngine = useLoaderEngine();
 *
 * return (
 *  <LoaderProvider engine={loaderEngine}>
 *     <Childs />
 *  </LoaderProvider>
 * )
 *
 * ```
 */
export const useLoaderEngine = () : ILoaderEngine =>
{
    const ref = useRef<ILoaderEngine>({ isLoading: false, showLoader: () => {}, hideLoader: () => {} });

    return {
        ...ref.current,
        __ref: ref
    } as ILoaderEngine;
};

export const isLoaderEngineRef = (param: ILoaderEngine): param is ILoaderEngine & { __ref: MutableRefObject<ILoaderEngine> } =>
{
    return (param as any).__ref.current !== undefined;
};