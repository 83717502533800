import { DialogActionsBar, Dialog as DialogKendo } from '@progress/kendo-react-dialogs';
import { Loader } from '@progress/kendo-react-indicators';
import { isString } from 'lodash';
import { FunctionComponent, VoidFunctionComponent } from 'react';

import { useGet } from '../Hooks/GetHook';
import { BlockedProvider } from '../Layout/BlockedProvider';
import { useRootElement, useTouchDevice, useBlocked, useBlockedSetter } from '../Layout/Hooks/LayoutHook';
import { useLoader, useLoading } from '../Loaders/LoaderHook';
import { LoaderProvider } from '../Loaders/LoaderProvider';
import { useNotification } from '../Notification/Hooks/NotificationHook';
import { Notifications } from '../Notification/NotificationComponent';
import { NotificationsProvider } from '../Notification/NotificationsProvider';

import { DialogEngine } from './Hooks/DialogHook';
import { IDialogProps } from './IDialogProps';

import './DialogTheme.scss';

/**
 * Dialog core component.
 *
 * If you want to control the component programatically, use `useDialogEngine();`
 *
 * @param {IDialogProps} props
 */
export const Dialog: FunctionComponent<IDialogProps> = ({
    engine,
    ...restProps
}: IDialogProps) =>
{
    // Control the visibility of his component with the engine if provided
    // If the engine is not provided, then this component is always visible and her parent is the reponsible to show or hide.
    const visible = engine === undefined ? true : engine.isVisible();

    return (
        <>
            {visible &&
                <NotificationsProvider>
                    <LoaderProvider>
                        <BlockedProvider>
                            <InnerDialog engine={engine} {...restProps} />
                        </BlockedProvider>
                    </LoaderProvider>
                </NotificationsProvider>
            }
        </>
    );
};

const InnerDialog: FunctionComponent<IDialogProps> = ({
    className = '',
    title,
    width = 'medium',
    height = 'medium',
    onClose,
    onOpen,
    actions,
    children,
    engine,
    appendTo = null,
}: IDialogProps) =>
{
    const rootElement = useRootElement();

    const widthClassname = width ? `${width === 'medium' ? 'md' : (width === 'large' ? 'lg' : 'sm')}W` : null;
    const heightClassname = height ? `${height === 'medium' ? 'md' : (height === 'large' ? 'lg' : 'sm')}H` : null;

    const _children = !Array.isArray(children) ?
        [children]
        : children;

    const isBlocked = useBlocked();
    const touchDevice = useTouchDevice();

    // #region Configure engine with the dialog

    const showNotification = useNotification();
    const loader = useLoader();
    const setBlocked = useBlockedSetter();

    (engine as DialogEngine | undefined)?.configure({
        showNotification,
        loader,
        setBlocked
    });

    // #endregion

    useGet(() =>
    {
        onOpen?.();
    });

    const _actionsBlocker = actions ?
        (<DialogActionsBar {...{ layout: (touchDevice ? 'stretched' : 'end') }}>
            { actions }
            <div className={`blocked inActions ${!isBlocked ? 'hide' : ''}`} />
        </DialogActionsBar>)
        : (<div className={`blocked ${!isBlocked ? 'hide' : ''}`} />);

    return (
        <DialogKendo className={`dialogWrap ${className} ${widthClassname ? widthClassname : ''} ${heightClassname ? heightClassname : ''}` }
            title={isString(title) ?
                <>
                    <span className="title">{title}</span>
                    <Notifications />
                    <DialogLoading />
                </>
                : title}
            onClose={() =>
            {
                engine?.hide();
                onClose?.();
            }}
            closeIcon={onClose ? true : false}
            appendTo={appendTo || rootElement}>
            { _children }
            {_actionsBlocker}
        </DialogKendo>
    );
};

Dialog.displayName = 'Dialog';

export const ConfirmationDialogs: VoidFunctionComponent<{id?: string}> = ({ id = 'dialogs-confirmation-id' }: {id?: string}) =>
{
    return (
        <div id={id}></div>
    );
};

ConfirmationDialogs.displayName = 'ConfirmationDialogs';

/**
 * Loader for the `<Dialog />`
 */
export const DialogLoading: VoidFunctionComponent = () =>
{
    const isLoading = useLoading();
    return (<span className="dialogLoaderWrap" {...(!isLoading && { style: { display: 'none' } })}>
        <Loader type="converging-spinner" size="small" />
    </span>);
};

DialogLoading.displayName = 'DialogLoading';