import { sum } from 'lodash';
import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { GraficaRevisionesDTO, GraficaDescargaVehiculoDTO, RevisionesPendientesDTO } from './I000000DTO';
import { DonutChartWidget } from './Widgets/DonutChartWidgetComponent';

interface IVehiculosPendientesRevisionChartProps
{
    revisiones: Array<RevisionesPendientesDTO>;
}

export const VehiculosPendientesRevisionChart: VoidFunctionComponent<IVehiculosPendientesRevisionChartProps> = ({ revisiones }) =>
{
    const { t } = useTranslation(['/FMT/I/I00/I000000/I000000'], { useSuspense: false });
    const navigate = useNavigate();

    const pendientesRevision = revisiones.filter(e => e.diasPendientes > 0 && e.diasPendientes <= 60);
    const fueraPlazo = revisiones.filter(e => e.diasPendientes <= 0);

    const datosGrafica: Array<GraficaRevisionesDTO> = [
        {
            denominacionTipo: t('widgetRevisiones.pendientesRevisar'),
            numeroElementos: pendientesRevision.length,
            color: '#f8B85E'
        },
        {
            denominacionTipo: t('widgetRevisiones.fueraPlazo'),
            numeroElementos: fueraPlazo.length,
            color: '#C7001E'
        }
    ];

    const donutCenterRender = () => (
        <>
            <h5 className='totalVehiculos'>
                {sum(datosGrafica.map(e => e.numeroElementos))}
            </h5>
            <p>Total</p>
        </>
    );

    return (
        <DonutChartWidget<GraficaDescargaVehiculoDTO>
            title={t('widgetRevisiones.titulo')}
            className='revisiones'
            data={datosGrafica}
            categoryField='denominacionTipo'
            field='numeroElementos'
            donutCenterRender={donutCenterRender}
            onClick={() => navigate('/V000002')}>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetRevisiones.fueraPlazo'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetRevisiones.fueraPlazo')} </p>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetRevisiones.pendientesRevisar'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetRevisiones.pendientesRevisar')} </p>
        </DonutChartWidget>
    );
};
VehiculosPendientesRevisionChart.displayName = 'VehiculosPendientesRevisionChart';
