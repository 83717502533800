import base64 from 'base-64';

import { IReportConfigurationDTO } from 'Services/DTOs/Report/IReport';

import { IBackendReportService } from './IBackendReportService';
import { IFetchAuthService } from './IFetchAuthService';

const baseUrlReporting = process.env.REACT_APP_REPORTING_API_ENDPOINT;

export class BackendReportServiceImpl implements IBackendReportService
{
    #fetchAuthService: IFetchAuthService;

    constructor(fetchAuthService: IFetchAuthService)
    {
        this.#fetchAuthService = fetchAuthService;
    }

    async openReport(url: string, body: IReportConfigurationDTO, download: boolean = false): Promise<void>
    {
        for (const key in body.ParameterValues)
        {
            body.ParameterValues[key] = base64.encode(JSON.stringify(body.ParameterValues[key]));
        }

        const finalUrl = `${baseUrlReporting}${url}/GetReport`;

        const requestInit: RequestInit = {
            method: 'POST',
            body: body ? JSON.stringify(body) : undefined,
            headers: { 'content-type': 'application/json', 'XMLHttpRequestResponseType': 'blob' }
        };

        if (download)
        {
            return await this.#fetchAuthService.download(finalUrl, requestInit);
        }
        else
        {
            return await this.#fetchAuthService.open(finalUrl, requestInit);
        }
    }
}