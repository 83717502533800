import { FunctionComponent } from 'react';

import { generateRouter } from 'Components/FMT/Router';

const context = require.context('./', true, /([a-zA-Z][0-9]{6,6}\.tsx)$/, 'lazy');
const TransactionRouter = generateRouter(context, 'G00');

export const G00Router: FunctionComponent = () =>
{
    return (
        <>
            <TransactionRouter />
        </>
    );
};

G00Router.displayName = 'G00Router';