import { createContext, useState } from 'react';

import { IInnerBreadcrumbContext, IInnerBreadcrumbProviderProps } from './IInnerBreadcrumbProviderProps';

export const InnerBreadcrumbContext = createContext<IInnerBreadcrumbContext>({
    breadcrumb: [],
    setBreadcrumb: (breadcrumb) => {}
});

export const InnerBreadcrumbProvider = (props: IInnerBreadcrumbProviderProps) =>
{
    const [breadcrumb, setBreadcrumb] = useState<string | Array<string>>([]);

    const contextValue = {
        breadcrumb,
        setBreadcrumb
    };

    return (
        <InnerBreadcrumbContext.Provider value={contextValue}>
            { props.children }
        </InnerBreadcrumbContext.Provider>
    );
};