import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { IconSolid } from 'Theme/Icons';
import { VoidFunctionComponent, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Alert } from 'Components/Core/Alert/AlertComponent';
import { DivRow } from 'Components/Core/Layout/DivComponent';
import { useAuthenticationService } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { IModifyPasswordFormProps } from './ILoginFormProps';

interface IModifyPasswordFormDTO
{
    password: string;
    repeatedPassword: string;
}

export const ModifyPasswordForm: VoidFunctionComponent<IModifyPasswordFormProps> = ({
    className,
    token,
    onBeforeSubmit = () => {},
    onAfterSubmit = () => {},
    onSuccess
}) =>
{
    const { t } = useTranslation('/FMT/I/I01/I010000/I010000');
    const [cargando, setCargando] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const authenticationService = useAuthenticationService();
    const verifyPassword = useVerifyPassword();

    // #region Formulario cambio de contraseña
    const onSubmitModifyPasswordForm = async ({ password, repeatedPassword }: IModifyPasswordFormDTO) =>
    {
        if (!verifyPassword(password))
        {
            setError(t('ModifyPasswordForm.error'));

            return;
        }

        if (password !== repeatedPassword)
        {
            setError(t('ModifyPasswordForm.errorRepeat'));

            return;
        }

        onBeforeSubmit();

        setCargando(true);
        setError('');

        try
        {
            const resultado = await authenticationService.setNewPassword(password, repeatedPassword);

            onSuccess(resultado);

            onAfterSubmit(true);
        }
        catch (e)
        {
            setError('otp.error');
            setCargando(false);
            onAfterSubmit(false);
        }
    };
    // #endregion

    return (
        <div className='wrap2FAPassword'>
            <Form
                onSubmit={onSubmitModifyPasswordForm}
                render={({ handleSubmit }) => (
                    <form id="ModifyPasswordForm" className={className} onSubmit={handleSubmit}>
                        <DivRow>
                            <Field
                                name="password"
                                render={({ input }) => (
                                    <Input
                                        {...input}
                                        className="form-control"
                                        id="password"
                                        type="password"
                                        label={t('ModifyPasswordForm.newPassword')}
                                        required={true}
                                        validityStyles={false}
                                        autoComplete="off"/>
                                )}/>
                            <div className='passwordRules'>
                                {t('ModifyPasswordForm.rules')}
                            </div>
                        </DivRow>
                        <DivRow>
                            <Field
                                name="repeatedPassword"
                                render={({ input }) => (
                                    <Input
                                        {...input}
                                        className="form-control"
                                        id="repeatedPassword"
                                        type="password"
                                        label={t('ModifyPasswordForm.repeatPassword')}
                                        required={true}
                                        validityStyles={false}
                                        autoComplete="off"/>
                                )}/>
                        </DivRow>
                        <DivRow className="btnWrap">
                            <Button type="submit" themeColor="primary" iconClass={!cargando ? IconSolid.arrowRight : ''}>
                                {cargando && <Loader type="converging-spinner" size="small" themeColor="light" />}
                                {t('btnSend2FA')}
                            </Button>
                        </DivRow>
                        {error && <Alert type="danger">{error}</Alert>}
                    </form>
                )}/>
        </div>
    );
};

ModifyPasswordForm.displayName = 'ModifyPasswordForm';

const useVerifyPassword = () => (text: string) =>
{
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;

    return passwordRegex.test(text);
};