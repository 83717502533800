import { useRef } from 'react';

/**
 * A simple hook to create a constant value that lives for
 * the lifetime of the component.
 *
 * Plagiarized from https://github.com/Andarist/use-constant
 *
 * Do NOT reuse this code unless you know what you're doing.
 * Use Andarist's hook; it's more fault tolerant to things like
 * falsy values.
 *
 * https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 *
 * @param {Function} init - A function to generate the value
 */
export function useConstant<T>(init: () => T): T
{
    const ref = useRef<{ v: T }>();
    if (!ref.current)
    {
        ref.current = { v: init() };
    }
    return ref.current.v;
}