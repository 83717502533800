import { Button as ButtonKendo } from '@progress/kendo-react-buttons';
import { FCRef, forwardRef, MouseEvent, useImperativeHandle, useRef } from 'react';

import { useBlockedSetter } from '../Layout/Hooks/LayoutHook';
import { useLoader } from '../Loaders/LoaderHook';

import { ButtonRefType, IButtonProps } from './IButtonProps';

import './ButtonTheme.scss';

/**
 * Kendo button wrapper with predefined features.
 * By default, it's type is 'button'.
 *
 * @param {IButtonProps} props
 */
export const Button: FCRef<IButtonProps, ButtonRefType> = forwardRef<ButtonRefType, IButtonProps>(({
    onClick,
    ...restProps
}, ref) =>
{
    const { showLoader, hideLoader } = useLoader();
    const setBlocked = useBlockedSetter();

    const _onClick = async (e: MouseEvent<HTMLButtonElement>) =>
    {
        showLoader();
        setBlocked(true);

        try
        {
            onClick && await onClick(e);
        }
        finally
        {
            setBlocked(false);
            hideLoader();
        }
    };

    return (
        <InnerButton ref={ref} onClick={_onClick} {...restProps} />
    );
});

Button.displayName = 'Button';

const InnerButton: FCRef<IButtonProps, ButtonRefType> = forwardRef<ButtonRefType, IButtonProps>(({
    type = 'button',
    onClick = () => {},
    ...restProps
}, ref) =>
{
    const buttonRef = useRef<ButtonKendo>(null);

    useImperativeHandle(ref, () => buttonRef.current?.element ?? undefined);

    const _onClick = async (e: MouseEvent<HTMLButtonElement>) =>
    {
        try
        {
            buttonRef.current?.element?.setAttribute('disabled', '');

            await onClick(e);
        }
        finally
        {
            buttonRef.current?.element?.removeAttribute('disabled');
        }
    };

    return (
        <ButtonKendo {...restProps} type={type} onClick={_onClick} ref={buttonRef}>
            { restProps.children }
        </ButtonKendo>
    );
});

InnerButton.displayName = 'InnerButton';