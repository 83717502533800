import { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';

import { useAuthenticationService } from 'Components/Core/Services/Hooks/AuthenticationHook';

/**
 * Private route component which checks if there's a logged user.
 *
 * If there isn't a logged user, it'll redirect login keeping the location which was trying to access.
 *
 * @param {RouteProps} props
 */
export const PrivateRoute: FunctionComponent<RouteProps> = ({ component: Component, children, ...rest }: RouteProps) =>
{
    const authenticationService = useAuthenticationService();

    const renderIfLogged = (props: RouteComponentProps<any>) =>
    {
        const isLoggedIn = authenticationService.isLogged();
        const tokenExpiration = authenticationService.isCurrentTokenExpired();

        if (!isLoggedIn && process.env.NODE_ENV === 'development')
        {
            console.log('Not logged. Redirecting login.');
        }

        if (tokenExpiration && process.env.NODE_ENV === 'development')
        {
            console.log('Expired token. Redirecting login.');
        }

        return (isLoggedIn && !tokenExpiration) ? (
            Component ? <Component {...props} /> : children
        ) : (
            <Redirect
                push // We dont want to reload!
                to={{
                    pathname: '/login',
                    state: { from: props.location }
                }}
            />
        );

    };
    return (
        <Route
            {...rest}
            render={renderIfLogged}
        />
    );
};