import { FunctionComponent } from 'react';

import { G00Router } from './G00/G00Router';
import { G01Router } from './G01/G01Router';

export const GRouter: FunctionComponent = () =>
{
    return (
        <>
            <G00Router />
            <G01Router />
        </>

    );
};

GRouter.displayName = 'GRouter';