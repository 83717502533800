import { toDataSourceRequestString } from '@progress/kendo-data-query';

import { IBackendSelectorService, IBackendSelectorServiceFactory } from 'Services/Backend/IBackendSelectorService';
import { CheckedData } from 'Services/DTOs/Checked';
import { DataSourceSimpleResult, GridSimpleResult, IGridSimpleState } from 'Services/DTOs/Grid/IGrid';
import { ResponseDTO } from 'Services/DTOs/IResponse';
import { SelectorDTO } from 'Services/DTOs/SelectorDTO';

import { IHttpService, QueryParams } from './IHttpService';

export class BackendSelectorServiceFactory implements IBackendSelectorServiceFactory
{
    #httpService: IHttpService;

    constructor(httpService: IHttpService)
    {
        this.#httpService = httpService;
    }

    get(transactionId: string) : IBackendSelectorService
    {
        return new BackendSelectorServiceImpl(transactionId, this.#httpService);
    }
}

class BackendSelectorServiceImpl implements IBackendSelectorService
{
    #transactionId: string;
    #httpService: IHttpService;

    constructor(transactionId: string, httpService: IHttpService)
    {
        this.#transactionId = transactionId;
        this.#httpService = httpService;
    }

    // #region Get
    async get<T extends SelectorDTO<string | number>>(url: string, gridDataState: IGridSimpleState): Promise<GridSimpleResult<CheckedData<T>>>;
    async get<T extends SelectorDTO<string | number>>(url: string, queryParam: QueryParams, gridDataState: IGridSimpleState): Promise<GridSimpleResult<CheckedData<T>>>;
    async get<T extends SelectorDTO<string | number>>(url: string, queryParamOrGridState: QueryParams | IGridSimpleState, gridDataStateUndefinable?: IGridSimpleState): Promise<GridSimpleResult<CheckedData<T>>>
    {
        const gridDataState = gridDataStateUndefinable ?
            gridDataStateUndefinable
            : queryParamOrGridState as IGridSimpleState;

        const queryParams = gridDataStateUndefinable ?
            `?${Object.entries(queryParamOrGridState as QueryParams).reduce((prev, [key, value]) => `${prev}${key}=${value}&`, '')}`  // Serialize the query params.
            : '';

        const stateQueryStr = (queryParams || url.indexOf('?') > 0 ? '&' : '?') + toDataSourceRequestString(gridDataState); // Serialize the state.

        const { data: result } = await this.#httpService.get<ResponseDTO<DataSourceSimpleResult<CheckedData<T>>>>(`${this.#transactionId}/${url}${queryParams}${stateQueryStr}`);

        if (result.errors)
        {
            throw new Error(result.errors);
        }

        // Wrapping result inside Selected type to add the selected property
        const data = result.data.map<CheckedData<T>>(e =>
        {
            return {
                ...e,
                // If not defined selected, set to false, otherwise use the selected field from the backend.
                checked: e.checked !== undefined ? e.checked : false
            };
        });

        return {
            ...result,
            data
        };
    }
    // #endregion
}