import { Loader, Skeleton } from '@progress/kendo-react-indicators';
import { HTMLAttributes, VoidFunctionComponent } from 'react';

import { useAuthenticationService } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { Button } from '../Button/ButtonComponent';
import { useTouchDevice } from '../Layout/Hooks/LayoutHook';

import './FallbackTheme.scss';

export const FallbackLayout: VoidFunctionComponent = () =>
{
    const authenticationService = useAuthenticationService();
    const logged = authenticationService.currentUser !== null;

    return (
        <>
            {!logged &&
                <div className="k-loading-mask">
                    <Loader type="converging-spinner"/>
                </div>
            }
            {logged &&
                <div className="wrapper">
                    <div className="content">
                        <header>
                            <FallbackNavTopBar />
                        </header>
                        <main>
                            <FallbackNavSidebar />
                            <FallbackDiv />
                        </main>
                    </div>
                </div>
            }
        </>
    );
};

FallbackLayout.displayName = 'FallbackLayout';

export const FallbackDiv: VoidFunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
    className = '',
    ...restProps
}) => (
    <div {...restProps} className={`fallback ${className}`}>
        <div className="loaderWrap">
            <Loader type="converging-spinner" size="small" />
        </div>
    </div>
);

FallbackDiv.displayName = 'FallbackDiv';

export const FallbackNavTopBar: VoidFunctionComponent = () =>
{
    const touchDevice = useTouchDevice();

    return (
        <nav className={`k-appbar k-appbar-dark${touchDevice ? ' touch' : ''}`} id="navTopbar">
            <div className={`k-appbar-section sidebarBtn ${!touchDevice ? 'd-inline-flex d-md-none' : ''}`}>
                <Skeleton animation={{ type: 'wave' }} style={{ width: '1.5rem', height: '2.375rem', marginLeft: '0.375rem' }} />
            </div>

            <div className="k-appbar-section transaction">
                <div aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{ margin: 0 }}>
                        <li className="breadcrumb-item d-none d-lg-block">
                            <Skeleton animation={{ type: 'wave' }} style={{ width: '12.5rem' }} />
                        </li>
                    </ol>
                </div>
            </div>

            <span className="k-appbar-spacer d-none d-sm-inline-flex" />

            <div className="k-appbar-section finder" />

            <div className="k-appbar-section d-inline-flex newTicket">
                <Button fillMode="flat" size={null}
                    className="k-button-icon">
                    <span className="la-stack" />
                </Button>
            </div>

            <div className="k-appbar-section">
                <div className="avatar">
                    <Skeleton shape="circle" animation={{ type: 'wave' }} style={{ width: '2rem', height: '2rem' }} />
                </div>
            </div>
        </nav>
    );
};

FallbackNavTopBar.displayName = 'FallbackNavTopBar';

export const FallbackNavSidebar: VoidFunctionComponent = () => (
    <nav id="navSidebar" className='expanded'>
        <main />
    </nav>
);

FallbackNavSidebar.displayName = 'FallbackNavSidebar';