import { ServicesTypes, useService } from '../ServiceProvider';

/**
 * Get a instance of `IAuthenticationService`
 *
 * @return {IAuthenticationService} AuthenticationService
 */
export const useAuthenticationService = () =>
{
    return useService(ServicesTypes.IAuthenticationService);
};

/**
 * Get the current user or do a logout.
 */
export const useCurrentUser = () =>
{
    const authenticationService = useAuthenticationService();

    if (!authenticationService.isLogged()
        || authenticationService.isCurrentTokenExpired()
        || authenticationService.modifyPassword()
        || authenticationService.isEnable2FA())
    {
        authenticationService.logout();
        globalThis.location.reload();
    }

    const currentUser = authenticationService.currentUser;

    if (currentUser)
    {
        return currentUser;
    }
    else
    {
        throw new Error('AuthenticationService is logged and not token expired but currentUser is `null`');
    }
};
