import { FunctionComponent } from 'react';

import { generateRouter } from 'Components/FMT/Router';

const context = require.context('./', true, /([a-zA-Z][0-9]{6,6}\.tsx)$/, 'lazy');
const TransactionRouter = generateRouter(context, 'I01');

export const I01Router: FunctionComponent = () =>
{
    return (
        <>
            <TransactionRouter exclude={['I010000']}/>
        </>
    );
};

I01Router.displayName = 'I01Router';