import { DateOnly, DateTime } from 'Services/Utils/Dates';

export interface Format {
    /**
     * Format all the fields of the data param recursively.
     *
     * @param {T} data, must be a `Array` or a `Object`.
     */
    format<T>(data: T): void;
}

export class DateFormat implements Format
{

    // Some valid examples:
    // 2017-06-22T09:34:12.11 => Local
    // 2016-12-31T00:00:00 => Local
    // 2021-05-31T22:00:00.0011Z => UTC
    // 2021-05-31T22:00:00.0011+01:00 => Offset (+01:00)
    private dateRegex = new RegExp(/^(\d{4})-0?(\d+)-0?(\d+)[T ]0?(\d+):0?(\d+):0?(\d+)(\.\d+)?(Z|(\+0?(\d+):0?(\d+)))?$/);

    public format<T>(object: T)
    {
        if (!object || !(object instanceof Object))
        {
            return;
        }

        for (const key in object) // Array or Object
        {
            const value = object[key];
            if (value instanceof Object)
            {
                this.format(value);
            }

            if (typeof value === 'string' && this.dateRegex.test(value))
            {
                if (value.endsWith('T00:00:00Z') || value.endsWith('T00:00:00.000Z'))
                {
                    object[key] = new DateOnly(value) as any;
                }
                else
                {
                    object[key] = new DateTime(value) as any;
                }
            }
        }
    }
}

export class JsonFormatter
{
    private formatters: Format[] = [new DateFormat()];

    /**
     * Format the data with the available `formatters`.
     *
     * This method change the data param.
     *
     * @param {T[] | T} data
     */
    public format<T>(data: T[] | T)
    {
        this.formatters.forEach(i =>
        {
            i.format(data);
        });
    }
}
