import { isNumber, isString } from 'lodash';

import { DataSourceSimpleResult, GridSimpleResult, IGridSimpleState } from 'Services/DTOs/Grid/IGrid';
import { CRUDResponseDTO, DataResponseDTO, ResponseDTO } from 'Services/DTOs/IResponse';
import { CoreValorResultadoEnum } from 'Services/Enum/CoreValorResultadoEnum';
import { isGridSimpleState } from 'Services/Utils/GridUtils';
import { toDataSourceRequestString } from 'Services/Utils/KendoDataStateUtils';
import { WithPartialChanges } from 'Services/Utils/PartialChangesUtils';

import { DeepPartial, FieldValues } from 'Components/Core/Form/Hooks/Types/Utils';

import { IBackendFormService, IBackendFormServiceFactory, UpdateObject } from './IBackendFormService';
import { IHttpService, QueryParams } from './IHttpService';

export class BackendFormServiceFactory implements IBackendFormServiceFactory
{
    #httpService: IHttpService;

    constructor(httpService: IHttpService)
    {
        this.#httpService = httpService;
    }

    get(transactionId: string) : IBackendFormService
    {
        return new BackendFormServiceImpl(transactionId, this.#httpService);
    }
}

export class BackendFormServiceImpl implements IBackendFormService
{
    #transactionId: string;
    #httpService: IHttpService;

    constructor(transactionId: string, httpService: IHttpService)
    {
        this.#transactionId = transactionId;
        this.#httpService = httpService;
    }

    get<T>(url: string, codigo: string | number, queryParams?: QueryParams): Promise<T>;
    get<T>(url: string, gridDataState: IGridSimpleState): Promise<GridSimpleResult<T>>;
    get<T>(url: string, queryParams: QueryParams, gridDataState: IGridSimpleState): Promise<GridSimpleResult<T>>;
    get<T>(url: string, queryParams?: QueryParams): Promise<T>;
    get<T>(url: string, param1?: IGridSimpleState | QueryParams | string | number, param2?: IGridSimpleState | QueryParams): Promise<T> | Promise<GridSimpleResult<T>> | Promise<GridSimpleResult<T>> | Promise<T>
    {
        if (isNumber(param1) || isString(param1))
        {
            return this._getEntity<T>(url, param1, param2 as QueryParams | undefined);
        }
        else if (param1 !== undefined && isGridSimpleState(param1))
        {
            return this.list<T>(url, param1);
        }
        else if (param2 !== undefined && isGridSimpleState(param2))
        {
            return this.list<T>(url, param2, param1);
        }
        else if (param1 === undefined && param2 === undefined)
        {
            return this._get<T>(url);
        }
        else
        {
            return this._get<T>(url, param1);
        }
    }

    // #region Get

    private async _get<T>(url: string, params?: QueryParams): Promise<T>
    {
        const { data } = await this.#httpService.get<CRUDResponseDTO<T>>(`${this.#transactionId}/${url}`, params);

        return data;
    }

    private async _getEntity<T>(url: string, codigo: string | number, params?: QueryParams): Promise<T>
    {
        const { data } = await this.#httpService.get<CRUDResponseDTO<T>>(`${this.#transactionId}/${url}`, { code: codigo, ...params });

        return data;
    }

    private async list<T>(url: string, gridDataState: IGridSimpleState, queryParams?: QueryParams): Promise<GridSimpleResult<T>>
    {
        const stateQueryStr = gridDataState ? (url.indexOf('?') < 0 ? '?' : '&') + toDataSourceRequestString(gridDataState) : ''; // Serialize the state.

        const { data: result } = await this.#httpService.get<ResponseDTO<DataSourceSimpleResult<T>>>(`${this.#transactionId}/${url}${stateQueryStr}`, queryParams);

        if (result.errors)
        {
            throw new Error(result.errors);
        }

        return result;
    }

    async create<T extends {codigo: string | number | null }>(url: string, data: DeepPartial<T>): Promise<DataResponseDTO<void, NonNullable<T['codigo']>>>
    {
        const finalUrl = `${this.#transactionId}/${url}`;

        const _elem = { ...data };
        delete _elem.codigo; // We dont want to send the codigo = "" or any other value

        const json = await this.#httpService.post<DataResponseDTO<void, NonNullable<T['codigo']>>>(finalUrl, WithPartialChanges(_elem));

        if (json.result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(json.errorDescription);
        }

        return json;
    }

    async update<T extends { codigo: string | number | null }>(url: string, data: UpdateObject<T>): Promise<void>
    {
        const { result, errorDescription } = await this.#httpService.put<CRUDResponseDTO<number, NonNullable<T['codigo']>>>(`${this.#transactionId}/${url}`, WithPartialChanges(data));

        if (result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(errorDescription);
        }
    }

    async post<T>(url: string, body: FormData): Promise<T>
    async post<T>(url: string, body: FieldValues): Promise<T>
    {
        const { data } = await this.#httpService.post<CRUDResponseDTO<T>>(`${this.#transactionId}/${url}`, body);

        return data;
    }

    async put<T>(url: string, body: FieldValues): Promise<T>
    {
        const { data } = await this.#httpService.put<CRUDResponseDTO<T>>(`${this.#transactionId}/${url}`, WithPartialChanges(body));

        return data;
    }

    async delete<T extends { codigo: string | number | null; }>(url: string, codigo: NonNullable<T['codigo']>, queryParams?: QueryParams): Promise<void>;
    async delete<T = void>(url: string, queryParams?: QueryParams): Promise<T>;
    async delete<T>(url: string, param1: string | number | QueryParams | undefined, param2?: undefined | QueryParams): Promise<T | void>
    {
        if (isNumber(param1) || isString(param1))
        {
            return this._deleteEntity<T & { codigo: string | number | null }>(url, param1, param2);
        }
        else
        {
            const { data } = await this.#httpService.delete<ResponseDTO<T>>(`${this.#transactionId}/${url}`, param1);

            return data;
        }
    }

    async _deleteEntity<T extends { codigo: string | number | null }>(url: string, codigo: string | number, queryParams?: QueryParams ): Promise<void>
    {
        await this.#httpService.delete<CRUDResponseDTO<T, NonNullable<T['codigo']>>>(`${this.#transactionId}/${url}`, { code: codigo, ...queryParams });

        return;
    }

    // #endregion
}