import { AggregateDescriptor, CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { isEmpty, isPlainObject } from 'lodash';

import { NonUndefined } from 'Components/Core/Form/Hooks/Types/Utils';
import { SortType } from 'Components/Core/Grid/Column/IColumnProps';

import { GridSimpleResult, GroupData, IGridSimpleState } from '../DTOs/Grid/IGrid';

// #region  Typeguards

/**
 * Check if the param is a `State`
 * @param param any
 */
export function isState(param: NonUndefined<any>): param is State
{
    const state = param as State;
    const keys: Array<keyof State> = ['filter', 'group', 'skip', 'sort', 'take'];
    return keys.length > 0 && keys.filter((e: keyof State) => state[e] !== undefined).length > 0;
}

/**
 * Check if the param is a `IGridSimpleState`
 * @param param any
 */
export function isGridSimpleState(param: NonUndefined<any>): param is IGridSimpleState
{
    return  (param as IGridSimpleState).type === 'GridSimpleState';
}

/**
 *  Check if the `param` is a `GridResult<T>`.
 * @param param any
 */
export const isGridSimpleResult = <T>(param: any): param is GridSimpleResult<T>  =>
{
    return Array.isArray((param as GridSimpleResult<T>).data) && (param as GridSimpleResult<T>).total !== undefined;
};

/**
 * Check if the param is a `GroupData<T>`
 * @param param any
 */
export function isGroupData<T>(param: T | GroupData<T>): param is GroupData<T>
{
    return Array.isArray((param as GroupData<T>).items);
}

/**
 * Check if the param is a `Array<GroupData<T>>`
 * @param param any
 */
export function isArrayOfGroupData<T>(param: Array<T> | Array<GroupData<T>>): param is Array<GroupData<T>>
{
    return Array.isArray(param) && param[0] && isGroupData(param[0]);
}

/**
 * Check if the param is a `State`
 * @param param any
 */
export function isKendoState(param: any): param is State
{
    return (isPlainObject(param) && isEmpty(param)) ||
        (param as State).skip !== undefined ||
        (param as State).take !== undefined ||
        (param as State).filter !== undefined ||
        (param as State).sort !== undefined ||
        (param as State).group !== undefined;
}

/**
 * Check if the param is a `CompositeFilterDescriptor`
 * @param param any
 */
export function isCompositeFilterDescriptor(param: any): param is CompositeFilterDescriptor
{
    return (param as CompositeFilterDescriptor)?.filters !== undefined;
}

/**
 * Check if the param is a `AggregateDescriptor`
 * @param param any
 */
export function isAggregateDescriptor(param: any): param is AggregateDescriptor
{
    return (param as AggregateDescriptor)?.aggregate !== undefined;
}

/**
 * Check if the sort param is of type `SortType`
 * @param sort any
 * @returns
 */
export const isSortType = (sort: any): sort is SortType  =>
{
    return sort === 'asc' || sort === 'desc';
};
// #endregion

/**
 * If the param is an Array of GroupData (array with arrays inside it), return all items inside it in a single level deep.
 */
export function flatten<T>(param: Array<T> | Array<GroupData<T>>): Array<T>
{
    const flat: Array<T> = [];
    param.forEach((item: T | GroupData<T>) =>
    {
        if (isGroupData(item))
        {
            flat.push(...flatten(item.items));
        }
        else
        {
            flat.push(item);
        }
    });
    return flat;
}
