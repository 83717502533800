import { FunctionComponent } from 'react';

import { I00Router } from './I00/I00Router';
import { I01Router } from './I01/I01Router';

export const IRouter: FunctionComponent = () =>
{
    return (
        <>
            <I00Router />
            <I01Router />
        </>
    );
};

IRouter.displayName = 'IRouter';