import { useCallback } from 'react';
import { useContext } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { INotificationProps } from '../INotificationProps';
import { Notification } from '../NotificationComponent';
import { NotificationsContext } from '../NotificationsProvider';

/**
 * Create a notification programatically.
 *
 * Example:
 *
 * ```ts
 * const showNotification = useNotification();
 * showNotification();
 * ```
 * @returns {(props: IShowNotificationProps = ({ content: t('notification.saved')) }) => void} function to show the notification with a message. Default: `t('notification.saved')`
 */
export const useNotification = () =>
{
    const { t } = useTranslation();
    const notificationsId = useContext(NotificationsContext);

    /**
     * Show the notification with a message. Default: `{ content: t('notification.saved')) }`
     * @param {IShowNotificationProps} props
     */
    return useCallback((props: IShowNotificationProps = {
        content: t('notification.saved')
    }) =>
    {
        const { content, ...restProps } = props;
        const wrapper = document.getElementById(notificationsId);

        if (wrapper)
        {
            render(
                <Notification {...restProps} onExited={() => unmountComponentAtNode(wrapper)}>
                    {content}
                </Notification>,
                wrapper);
        }
        else
        {
            if (process.env.NODE_ENV === 'development')
            {
                console.warn(`The <Notifications id=${notificationsId} /> element hasn't been found`);
            }
        }
    }, [notificationsId, t]);
};

export interface IShowNotificationProps extends Omit<INotificationProps, 'onExited'> {
    content?: string;
}