import { FunctionComponent, PropsWithChildren, useEffect } from 'react';

import { ErrorBoundary } from '../ErrorBoundary';

import { useActiveTransaction } from './Hooks/TransactionHook';
import { ITransactionProps } from './ITransactionProps';

import './TransactionTheme.scss';

/**
 * The `<Transaction />` component is the basic layout of all transactions.
 *
 * Set the title of the transaction for the current transaction id.
 *
 * @param {ITransactionProps} props
 */
export const Transaction: FunctionComponent<ITransactionProps> = ({
    title,
    id,
    className,
    cardMode = true,
    children
}: PropsWithChildren<ITransactionProps>) =>
{
    // #region Hooks
    const { setActiveTransaction } = useActiveTransaction();
    // #endregion

    useEffect(() =>
    {
        setActiveTransaction({ title, id });
    }, [title, id, setActiveTransaction]);

    return (
        <ErrorBoundary>
            <div id={id} className={`transactionContainer ${cardMode ? 'cardMode' : ''} ${className ? className : ''}`}>
                {children}
            </div>
        </ErrorBoundary>
    );
};

Transaction.displayName = 'Transaction';