import { IHttpService } from 'Services/Backend/IHttpService';
import { ResponseDTO } from 'Services/DTOs/IResponse';

import { ITransactionService, ITransactionServiceFactory, TransactionPermissionDTO } from './ITransactionService';

export class TransactionServiceFactory implements ITransactionServiceFactory
{
    #httpService: IHttpService;

    constructor(httpService: IHttpService)
    {
        this.#httpService = httpService;
    }

    get(id: string): ITransactionService
    {
        return new TransactionServiceImpl(id, this.#httpService);
    }
}

class TransactionServiceImpl implements ITransactionService
{
    #id: string;
    #httpService: IHttpService;

    constructor(id: string, httpService: IHttpService)
    {
        this.#id = id;
        this.#httpService = httpService;
    }

    getId(): string
    {
        return this.#id;
    }

    async getPermission(): Promise<TransactionPermissionDTO>
    {
        const { data } = await this.#httpService.get<ResponseDTO<TransactionPermissionDTO>>(
            `${this.#id}/GetTransactionPermissionDTO`,
            { transactionKey:this.#id });

        return {
            ...data,
            allowedTransactionOperationsKeys: data.allowedTransactionOperationsKeys ?? []
        };
    }
}