import React, { FC, useContext } from 'react';

import { AuthenticationService } from 'Services/Authentication/AuthenticationService';
import { IAuthenticationService } from 'Services/Authentication/IAuthenticationService';
import { BackendFileServiceFactory } from 'Services/Backend/BackendFileService';
import { BackendFormServiceFactory } from 'Services/Backend/BackendFormService';
import { BackendGridServiceFactory } from 'Services/Backend/BackendGridService';
import { BackendPuzzleServiceFactory } from 'Services/Backend/BackendPuzzleService';
import { BackendReportServiceImpl } from 'Services/Backend/BackendReportService';
import { BackendSelectorServiceFactory } from 'Services/Backend/BackendSelectorService';
import { FetchAuthService } from 'Services/Backend/FetchAuthService';
import { HttpServiceImpl } from 'Services/Backend/HttpService';
import { IBackendFileServiceFactory } from 'Services/Backend/IBackendFileService';
import { IBackendFormServiceFactory } from 'Services/Backend/IBackendFormService';
import { IBackendGridServiceFactory } from 'Services/Backend/IBackendGridService';
import { IBackendPuzzleServiceFactory } from 'Services/Backend/IBackendPuzzleService';
import { IBackendReportService } from 'Services/Backend/IBackendReportService';
import { IBackendSelectorServiceFactory } from 'Services/Backend/IBackendSelectorService';
import { IFetchAuthService } from 'Services/Backend/IFetchAuthService';
import { IHttpService } from 'Services/Backend/IHttpService';
import { ITransactionServiceFactory } from 'Services/Transaction/ITransactionService';
import { TransactionServiceFactory } from 'Services/Transaction/TransactionService';

import { useConstant } from 'Components/Core/Hooks/ConstantHook';

export enum ServicesTypes
{
    IAuthenticationService = 'IAuthenticationService',
    IFetchAuthService = 'IFetchAuthService',
    IHttpService = 'IHttpService',
    IBackendFormServiceFactory = 'IBackendFormServiceFactory',
    IBackendGridServiceFactory = 'IBackendGridServiceFactory',
    IBackendPuzzleServiceFactory = 'IBackendPuzzleServiceFactory',
    ITransactionServiceFactory = 'ITransactionServiceFactory',
    IBackendSelectorServiceFactory = 'IBackendSelectorServiceFactory',
    IBackendReportService = 'IBackendReportService',
    IBackendFileServiceFactory = 'IBackendFileServiceFactory'
}

interface Services
{
    [ServicesTypes.IAuthenticationService] : IAuthenticationService,
    [ServicesTypes.IFetchAuthService] : IFetchAuthService,
    [ServicesTypes.IHttpService] : IHttpService,
    [ServicesTypes.IBackendFormServiceFactory] : IBackendFormServiceFactory,
    [ServicesTypes.IBackendGridServiceFactory] : IBackendGridServiceFactory,
    [ServicesTypes.ITransactionServiceFactory] : ITransactionServiceFactory,
    [ServicesTypes.IBackendPuzzleServiceFactory] : IBackendPuzzleServiceFactory,
    [ServicesTypes.IBackendSelectorServiceFactory] : IBackendSelectorServiceFactory,
    [ServicesTypes.IBackendReportService] : IBackendReportService,
    [ServicesTypes.IBackendFileServiceFactory] : IBackendFileServiceFactory,
}

export const ServicesContext = React.createContext<Services | null>(null);

/**
 * Register all services in the context.
 * All services are singleton and must be: a class or a factory.
 *
 * Work like a DI container and register all services in this container.
 * We use context to use the react way to access this singletons services but it also must work
 * with a simple static class.
 */
export const ServiceProvider: FC = ({ children }) =>
{
    const container = useConstant(() =>
    {
        // Register services and their dependencies
        const authService = new AuthenticationService();
        const fetchAuthService = new FetchAuthService(authService);
        const httpService = new HttpServiceImpl(fetchAuthService);
        const backendFormServiceFactory = new BackendFormServiceFactory(httpService);
        const backendGridServiceFactory = new BackendGridServiceFactory(httpService, fetchAuthService);
        const transactionServiceFactory = new TransactionServiceFactory(httpService);
        const backendPuzzleServiceFactory = new BackendPuzzleServiceFactory(httpService);
        const backendSelectorServiceFactory = new BackendSelectorServiceFactory(httpService);
        const backendReportService = new BackendReportServiceImpl(fetchAuthService);
        const backendFileServiceFactory = new BackendFileServiceFactory(fetchAuthService, httpService);

        return {
            [ServicesTypes.IAuthenticationService]: authService,
            [ServicesTypes.IFetchAuthService]: fetchAuthService,
            [ServicesTypes.IHttpService]: httpService,
            [ServicesTypes.IBackendFormServiceFactory]: backendFormServiceFactory,
            [ServicesTypes.IBackendGridServiceFactory]: backendGridServiceFactory,
            [ServicesTypes.IBackendPuzzleServiceFactory]: backendPuzzleServiceFactory,
            [ServicesTypes.ITransactionServiceFactory]: transactionServiceFactory,
            [ServicesTypes.IBackendSelectorServiceFactory]: backendSelectorServiceFactory,
            [ServicesTypes.IBackendReportService]: backendReportService,
            [ServicesTypes.IBackendFileServiceFactory]: backendFileServiceFactory,
        };
    });

    return <ServicesContext.Provider value={container}>
        { children }
    </ServicesContext.Provider>;
};

/**
 * Get the service from the ServiceContext
 * @param type - The service type to get
 * @returns The service
 */
export const useService = <T extends ServicesTypes>(type: T) : Services[T] =>
{
    const servicesLocator = useContext(ServicesContext);

    if (servicesLocator === null)
    {
        throw new Error('Not found context for ServiceContext');
    }

    const result = servicesLocator[type];

    if (result)
    {
        return result;
    }

    throw new Error(`Not found dependency: ${type} in ServiceContext`);
};