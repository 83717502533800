import { CustomGroupFilterDTO } from './IPuzzle';

export class CustomGroupFilterRequest
{
    public customGroupFilters: Array<CustomGroupFilter>;

    constructor(customGroupFilters: Array<CustomGroupFilter>)
    {
        this.customGroupFilters = customGroupFilters;
    }

    public static build(filters: Array<CustomGroupFilterDTO>): CustomGroupFilterRequest
    {
        return new CustomGroupFilterRequest(filters.map(g => ({ key: g.key, customFilters: g.customFilters.map(f => ({ key: f.key } )) })));
    }
}

interface CustomGroupFilter
{
    key: string;
    customFilters: Array<CustomFilter>;
}

interface CustomFilter
{
    key: string;
}