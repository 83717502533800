import { CSSProperties, FCRef, forwardRef } from 'react';

import { IDivProps } from './IDivProps';

import './DivTheme.scss';

/**
 * Div component for layout as rows using css flexbox.
 * @param {IDivProps} props
 * @returns DivRow
 */
export const DivRow : FCRef<IDivProps, HTMLDivElement> = forwardRef<HTMLDivElement, IDivProps>((props: IDivProps, ref) =>
    <DivComponent {...props} ref={ref} type="row" />);

DivRow.displayName = 'DivRow';

/**
 * Div component for layout as columns using css flexbox.
 * Works perfecty inside DivRow components.
 * @param {IDivProps} props
 * @returns DivColumn
 */
export const DivColumn : FCRef<IDivProps, HTMLDivElement> = forwardRef<HTMLDivElement, IDivProps>((props: IDivProps, ref) =>
    <DivComponent {...props} ref={ref} type="column" />);

DivColumn.displayName = 'DivColumn';

type DivComponentType = IDivProps & { type: 'row' | 'column'; };

const DivComponent: FCRef<DivComponentType, HTMLDivElement> = forwardRef<HTMLDivElement, DivComponentType>(({
    type,
    className = '',
    width,
    minWidth,
    style,
    children,
    ...restProps
}: DivComponentType, ref) =>
{
    const _finalStyles: CSSProperties = style ?? {};

    if (width && !_finalStyles.width)
    {
        _finalStyles.width = width;
    }

    if (minWidth && !_finalStyles.minWidth)
    {
        _finalStyles.minWidth = minWidth;
    }

    return (
        <div {...restProps}
            ref={ref}
            className={`${type} ${className}`}
            style={_finalStyles}>
            { children }
        </div>
    );
});

DivComponent.displayName = 'DivComponent';