import { BackendModule, InitOptions, ReadCallback, ResourceKey, ResourceLanguage, Services } from 'i18next';
import JSONC from 'jsonc-simple-parser';

export interface BackendOptions {

}

const NOT_FOUND = 'MODULE_NOT_FOUND';

/**
 * Load a i18n file for a namespace and a lang.
 *
 * @param {string} namespace starting with a slash
 * @param {string} language `'en'` or `'es'` are the supported values
 * @return a `ResourceKey` if the file is found.
 * If not found return a rejected promise with a string `'MODULE_NOT_FOUND'`.
 * If something wrong happens return a rejected promise with a `Error` exception.
 */
const importI18n = (namespace: string, language: string): Promise<ResourceKey> =>
{
    return new Promise<ResourceKey>((resolve, reject) =>
    {
        import(
            // eslint-disable-next-line capitalized-comments
            /* webpackMode: "lazy" */
            /* webpackInclude: /(_es|_en)\.json$/ */
            `../../Components${namespace}_${language}.json`)
            .then(value =>
            {
                resolve(value);
            }, err =>
            {
                import(
                    // eslint-disable-next-line capitalized-comments
                    /* webpackMode: "lazy" */
                    /* webpackInclude: /(_es|_en)\.jsonc$/ */
                    `../../Components${namespace}_${language}.jsonc`)
                    .then(value =>
                    {
                        return value.default;
                    })
                    .then(location =>
                    {
                        // Webpack with CRA dont allow to add custom resolver for jsonc or json5 files,
                        // We must load with `fetch` and process the content manually with `JSONC.parse`
                        return fetch(location);
                    }).then(response =>
                    {
                        if (response.ok)
                        {
                            return response.text();
                        }
                        else if (response.status > 400 && response.status < 500)
                        {
                            throw new Error(NOT_FOUND);
                        }
                        else
                        {
                            throw new Error(response.statusText);
                        }
                    }).then(text =>
                    {
                        resolve(JSONC.parse(text));
                    }).catch(err =>
                    {
                        // If err is thrown by import(...) then it have a code
                        // If err is thrown by fetch() then it have a message
                        // Otherwise is a uncontrolled error
                        if (err?.code === NOT_FOUND || err?.message === NOT_FOUND)
                        {
                            reject(NOT_FOUND);
                        }
                        else
                        {
                            reject(err);
                        }
                    });
            });
    });

};

export class Backend  implements BackendModule<BackendOptions>
{

    private services?: Services;

    private backendOptions?: BackendOptions;

    private i18nextOptions?: InitOptions;

    private defaultNS: string = '/General';

    type: 'backend' = 'backend';

    constructor()
    {
        this.type = 'backend';
    }

    init(services: Services, backendOptions: BackendOptions, i18nextOptions: InitOptions): void
    {
        this.services = services;
        this.backendOptions = backendOptions;
        this.i18nextOptions = i18nextOptions;
    }

    read(language: string, namespace: string = '/General', callback: ReadCallback): void
    {
        if (process.env.NODE_ENV === 'development')
        {
            console.log('Loading lang...', language, namespace);
        }

        if (process.env.NODE_ENV === 'test' || namespace === 'translation')
        {
            // Ignore...
            callback(null, {});
            return;
        }

        // Import files relatives to .src/Componnets/
        // All namespace must start with a slash '/'
        importI18n(namespace, language)
            .then(value =>
            {
                callback(null, value);
            }, err =>
            {
                if (err === NOT_FOUND)
                {
                    // Resource not found we dont want to retry
                    callback(new Error(`⚠️ Lang not found: ./src/Components${namespace}_${language}`), false);
                }
                else
                {
                    // Something wrong, we want to retry
                    callback(new Error(`❌ Error loading lang: ./src/Components${namespace}_${language}`), true);
                }
            });
    }

    create(languages: string[], namespace: string, key: string, fallbackValue: string): void
    {
        throw new Error('Method not implemented.');
    }

    save?(language: string, namespace: string, data: ResourceLanguage): void
    {
        throw new Error('Method not implemented.');
    }
}