import { CustomGroupFilterRequest } from 'Services/DTOs/Grid/CustomGroupFilterRequest';
import { CustomGroupFilterDTO, GridConfigurationDTO, PuzzleConfigurationDTO } from 'Services/DTOs/Grid/IPuzzle';
import { CRUDResponseDTO, DataResponseDTO, ResponseDTO } from 'Services/DTOs/IResponse';
import { CoreValorResultadoEnum } from 'Services/Enum/CoreValorResultadoEnum';

import { IBackendPuzzleService, IBackendPuzzleServiceFactory } from './IBackendPuzzleService';
import { IHttpService } from './IHttpService';

export class BackendPuzzleServiceFactory implements IBackendPuzzleServiceFactory
{
    #httpService: IHttpService;

    constructor(httpService: IHttpService)
    {
        this.#httpService = httpService;
    }

    get(transactionId: string) : IBackendPuzzleService
    {
        return new BackendPuzzleServiceImpl(transactionId, this.#httpService);
    }
}

class BackendPuzzleServiceImpl implements IBackendPuzzleService
{
    #transactionId: string;
    #httpService: IHttpService;

    constructor(transactionId: string, httpService: IHttpService)
    {
        this.#transactionId = transactionId;
        this.#httpService = httpService;
    }

    async getGridConfiguration(): Promise<GridConfigurationDTO>
    {
        const { status, data, errorDescription } = await this.#httpService.get<ResponseDTO<GridConfigurationDTO>>(`${this.#transactionId}/GetGridConfiguration`);

        if (status !== 1)
        {
            throw new Error(errorDescription);
        }

        return data;
    }

    async getFilterListValues(
        field: string,
        customFilters: Array<CustomGroupFilterDTO>): Promise<Array<string | number | boolean | Date>>
    {
        const customFiltersJSON = customFilters && CustomGroupFilterRequest.build(customFilters); // Only want key and customFilters

        const { data } = await this.#httpService.get<ResponseDTO<Array<string | number | boolean | Date>>>(`${this.#transactionId}/GetFilterListValues`,
            {
                field,
                customGroupFilterRequest: btoa(JSON.stringify(customFiltersJSON))
            });

        return data;
    };

    async getPuzzle(code: string): Promise<PuzzleConfigurationDTO>
    {
        const { result, errorDescription, data } = await this.#httpService.get<CRUDResponseDTO<PuzzleConfigurationDTO>>(`${this.#transactionId}/GetPuzzle`, { code });

        if (result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(errorDescription);
        }

        return data;
    };

    async createPuzzle(data: PuzzleConfigurationDTO): Promise<string>
    {
        const result = await this.#httpService.post<DataResponseDTO<void, string>>(`${this.#transactionId}/CreatePuzzle`, data);

        if (result.result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(result.errorDescription);
        }

        return result.code;
    };

    async updatePuzzle(data: PuzzleConfigurationDTO): Promise<void>
    {
        const result = await this.#httpService.put<DataResponseDTO<void, string>>(`${this.#transactionId}/UpdatePuzzle`, data);

        if (result.result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(result.errorDescription);
        }
    };

    async deletePuzzle(code: string): Promise<void>
    {
        const result = await this.#httpService.delete<DataResponseDTO<void, string>>(`${this.#transactionId}/DeletePuzzle`, { code });

        if (result.result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(result.errorDescription);
        }
    };

    async createFavoritePuzzle(code: string): Promise<void>
    {
        const result = await this.#httpService.post<DataResponseDTO<void, string>>(`${this.#transactionId}/CreateFavoritePuzzle`, { code });

        if (result.result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(result.errorDescription);
        }
    };

    async deleteFavoritePuzzle(code: string): Promise<void>
    {
        const result = await this.#httpService.delete<DataResponseDTO<void, string>>(`${this.#transactionId}/DeleteFavoritePuzzle`, { code });

        if (result.result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(result.errorDescription);
        }
    };

    async updatePrivacyPuzzle(puzzle: Pick<PuzzleConfigurationDTO, NonNullable<'code'> | 'public'>): Promise<void>
    {
        const result = await this.#httpService.put<DataResponseDTO<void, string>>(`${this.#transactionId}/UpdatePrivacyPuzzle`,  puzzle);

        if (result.result !== CoreValorResultadoEnum.OK)
        {
            throw new Error(result.errorDescription);
        }
    };
}