import { FunctionComponent } from 'react';

import { F00Router } from './F00/F00Router';
import { F01Router } from './F01/F01Router';

export const FRouter: FunctionComponent = () =>
{
    return (
        <>
            <F00Router />
            <F01Router />
        </>

    );
};

FRouter.displayName = 'FRouter';