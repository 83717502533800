import { IAuthenticationService } from 'Services/Authentication/IAuthenticationService';
import { ResponseDTO } from 'Services/DTOs/IResponse';

import { IFetchAuthService } from './IFetchAuthService';

export class FetchAuthService implements IFetchAuthService
{
    #authenticationService: IAuthenticationService;

    constructor(authenticationService: IAuthenticationService)
    {
        this.#authenticationService = authenticationService;
    }

    // https://stackoverflow.com/questions/46932213/how-to-download-large-file-with-javascript
    // https://stackoverflow.com/questions/52817280/problem-downloading-a-pdf-blob-in-javascript
    async download(url: RequestInfo, init: RequestInit | undefined): Promise<void>
    {
        const response = await this.fetch(url, init);
        const blob = await response.blob();
        const fileUrl = (window.URL || window.webkitURL).createObjectURL(blob);

        const hiddenAnchor = document.createElement('a');
        hiddenAnchor.style.display = 'none';
        hiddenAnchor.href = fileUrl;

        const contentDisposition = response.headers.get('content-disposition');
        if (contentDisposition)
        {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const fileNameRegexResult = fileNameRegex.exec(contentDisposition);

            if (fileNameRegexResult)
            {
                hiddenAnchor.download = decodeURI(fileNameRegexResult[1].replace(/['"]/g, ''));
            }
        }

        document.body.appendChild(hiddenAnchor);
        hiddenAnchor.click();
        document.body.removeChild(hiddenAnchor);

        (window.URL || window.webkitURL).revokeObjectURL(fileUrl);
    }

    // https://stackoverflow.com/questions/46932213/how-to-download-large-file-with-javascript
    // https://stackoverflow.com/questions/52817280/problem-downloading-a-pdf-blob-in-javascript
    async open(url: RequestInfo, init: RequestInit | undefined): Promise<void>
    {

        const response = await this.fetch(url, init);
        const blob = await response.blob();
        const fileUrl = (window.URL || window.webkitURL).createObjectURL(blob);

        const newWindow = window.open('', '_blank');
        if (newWindow)
        {
            newWindow.location.href = fileUrl;
        }

        (window.URL || window.webkitURL).revokeObjectURL(fileUrl);
    }

    async fetch(url: RequestInfo, init: RequestInit | undefined): Promise<Response>
    {
        const requestInit: RequestInit = init ?? {};
        requestInit.headers = { ...requestInit.headers, ...this.#authenticationService.getAuthHeader() };

        // Do the request & refresh token in parallel
        const [response, ] = await Promise.all([fetch(url, requestInit), this.refreshToken()]);

        if (!response.ok)
        {
            // Login incorrecto o token invalido => Error Forbidden
            if ([403].indexOf(response.status) !== -1)
            {
                // Hacemos el logout
                this.logout();

                return new Promise<Response>((resolve) =>
                {
                    setTimeout(() => resolve(response), 500);
                });
            }
            // No autorizado o sin permisos
            else if ([401].indexOf(response.status) !== -1)
            {
                // Redireccionamos al CUADRO DE MANDOS
                globalThis.location.replace('I000000');

                return new Promise<Response>((resolve) =>
                {
                    setTimeout(() => resolve(response), 500);
                });
            }
            // Error
            else
            {
                const err: ResponseDTO<void> = await response.json();
                throw new Error(err.error?.message || err.errorDescription || response.statusText);
            }
        }
        return response;
    }

    /**
      * Check if the token of the current logged user must be refreshed.
      *
      * If no user logged, then do a logout and reload the page.
      *
      * If the token cant be refreshed, the do a logout and reload the page.
      *
      * If the token must be refreshed (< 30 minutes to expire) to the refresh request and store
      * the new JWT token generated.
      */
    private async refreshToken()
    {
        const expiredDate = this.getAuthExpiredDate();
        const createdDate = this.getAuthCreatedDate();
        if (!expiredDate || !createdDate)
        {
            this.logout();
            // No se debe alcanzar este error, ya que hemos recargado la pagina
            throw new Error('Not authorized');
        }
        const ttl = diffMinutes(expiredDate, createdDate);
        // Simple logic if TTL si more than 1 hour => try to renew when we have 20 minutes left, else 10 minutes left
        if (diffMinutes(expiredDate, new Date()) < (ttl > 60 ? 20 : 10))
        {
            try
            {
                await this.#authenticationService.refreshToken();
            }
            catch (err: any)
            {
                this.logout();
                // No se debe alcanzar este error, ya que hemos recargado la pagina
                throw new Error(err);
            }
        }
    }

    private logout()
    {

        this.#authenticationService.logout();
        globalThis.location.reload();
    }

    private getAuthExpiredDate()
    {
        const token = this.#authenticationService.geCurrentToken();
        if (token)
        {
            const date = new Date(0);
            date.setUTCSeconds(token.exp);
            return date;
        }
        else
        {
            return null;
        }
    }

    private getAuthCreatedDate()
    {
        const token = this.#authenticationService.geCurrentToken();
        if (token)
        {
            const date = new Date(0);
            date.setUTCSeconds(token.nbf);
            return date;
        }
        else
        {
            return null;
        }
    }
}

const diffMinutes = (dt2: Date, dt1: Date) =>
{
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
};