import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { IconSolid } from 'Theme/Icons';
import { FunctionComponent, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { AccessResultDTO } from 'Services/Authentication/IAuthenticationService';

import { Alert } from 'Components/Core/Alert/AlertComponent';
import { DivRow } from 'Components/Core/Layout/DivComponent';
import { useAuthenticationService } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { IUserPassFormProps } from './ILoginFormProps';

interface IUserPassData {
    username: string;
    password: string;
}

export const UserPassForm : FunctionComponent<IUserPassFormProps> = ({
    className,
    onSuccess,
    on2FA,
    onModifyPassword,
    onBeforeSubmit = () => {},
    onAfterSubmit = () => {}
}) =>
{
    const { t } = useTranslation('/FMT/I/I01/I010000/I010000');
    const authenticationService = useAuthenticationService();

    const [deshabilitarForm, setDeshabilitarForm] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    // Evento de envío de datos del usuario
    const onSubmitForm = async ({ username, password }: IUserPassData) =>
    {
        onBeforeSubmit();
        setDeshabilitarForm(true);

        try
        {
            const result: AccessResultDTO = await authenticationService.login(username, password);

            if (result.enable2FA)
            {
                // Pantalla 2FA actual
                on2FA(result);
            }
            else
            {
                if (result.modifyPassword)
                {
                    // Pantalla nueva cambio password (con textbox 2FA DESACTIVADO E INVISBLE)
                    onModifyPassword(result);
                }
                else
                {
                    // Acceso normal
                    onSuccess(result);
                }
            }

            onAfterSubmit(true);
        }
        catch (e)
        {
            setError(true);

            setDeshabilitarForm(false);

            onAfterSubmit(false);
        }
    };

    return (
        <Form
            onSubmit={onSubmitForm}
            render={({ handleSubmit }) => (
                <form id="LoginUserPassForm" className={className} onSubmit={handleSubmit}>
                    <DivRow>
                        <Field
                            name="username"
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    className="form-control"
                                    id="email"
                                    type="text"
                                    label={t('email.label')}
                                    required={true}
                                    validationMessage={t('email.mandatory')}
                                    validityStyles={false}
                                    disabled={deshabilitarForm}
                                    autoComplete="off"/>
                            )}/>
                    </DivRow>
                    <DivRow className="passSubmit">
                        <Field
                            name="password"
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    className="form-control"
                                    id="password"
                                    type="password"
                                    label={t('password.label')}
                                    required={true}
                                    validationMessage={t('password.mandatory')}
                                    validityStyles={false}
                                    disabled={deshabilitarForm}
                                    autoComplete="off"/>
                            )}/>
                        <div className="btnWrap">
                            <Button disabled={deshabilitarForm} type="submit" themeColor="primary" iconClass={!deshabilitarForm ? IconSolid.arrowRight : ''}>
                                {t('btnLogin')}
                                {deshabilitarForm && <Loader type="converging-spinner" size="small" themeColor="light" />}
                            </Button>
                        </div>
                    </DivRow>
                    {error && <Alert type="danger">{t('error')}</Alert>}
                </form>
            )} />
    );
};

UserPassForm.displayName = 'LoginUserPassForm';
