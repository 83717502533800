import { FunctionComponent } from 'react';

import { V00Router } from './V00/V00Router';
import { V01Router } from './V01/V01Router';

export const VRouter: FunctionComponent = () =>
{
    return (
        <>
            <V00Router />
            <V01Router />
        </>

    );
};

VRouter.displayName = 'VRouter';