import { FunctionComponent } from 'react';

import { IAlertProps } from './IAlertProps';

/**
 * Show a message like in bootstrap
 * @see https://getbootstrap.com/docs/4.0/components/alerts/
 *
 * @param props {IAlertProps}
 */
export const Alert: FunctionComponent<IAlertProps> = ({ children, type, hide = false, title }: IAlertProps) =>
{
    return (
        <div className={`alert alert-${type}`} role="alert">
            {title && <h4 className="alert-heading"> { title }</h4>}
            { children }
            { hide && <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>}
        </div>
    );
};