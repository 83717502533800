import { sum } from 'lodash';
import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { GraficaTarjetaConductorDTO, TarjetaConductorDTO } from './I000000DTO';
import { DonutChartWidget } from './Widgets/DonutChartWidgetComponent';

interface ITarjetasConductoresProps
{
    tarjetasConductores: Array<TarjetaConductorDTO>;
}

export const TarjetasConductores: VoidFunctionComponent<ITarjetasConductoresProps> = ({ tarjetasConductores }) =>
{
    const { t } = useTranslation(['/FMT/I/I00/I000000/I000000'], { useSuspense: false });
    const navigate = useNavigate();

    const pendientesRenovar = tarjetasConductores.filter(e => e.diasPendientes > 0 && e.diasPendientes <= 60);
    const plazoCaducado = tarjetasConductores.filter(e => e.diasPendientes <= 0);

    const datosGrafica: Array<GraficaTarjetaConductorDTO> = [
        {
            denominacionTipo: t('widgetTarjetasConductores.pendientesRenovar'),
            numeroElementos: pendientesRenovar.length,
            color: '#f8B85E'
        },
        {
            denominacionTipo: t('widgetTarjetasConductores.plazoCaducado'),
            numeroElementos: plazoCaducado.length,
            color: '#C7001E'
        }
    ];

    const donutCenterRender = () => (
        <>
            <h5 className='totalVehiculos'>
                {sum(datosGrafica.map(e => e.numeroElementos))}
            </h5>
            <p>Total</p>
        </>
    );

    return (
        <DonutChartWidget<GraficaTarjetaConductorDTO>
            title={t('widgetTarjetasConductores.titulo')}
            className='tarjetasConductores'
            data={datosGrafica}
            categoryField='denominacionTipo'
            field='numeroElementos'
            donutCenterRender={donutCenterRender}
            onClick={() => navigate('/C000002')}>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetTarjetasConductores.plazoCaducado'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetTarjetasConductores.plazoCaducado')} </p>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetTarjetasConductores.pendientesRenovar'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetTarjetasConductores.pendientesRenovar')} </p>
        </DonutChartWidget>

    );
};
TarjetasConductores.displayName = 'VehiculosDescargaChart';
