import { isString } from 'lodash';

import { isNumber } from './Utils';

const dateOnlyRegex = /^(\d{4})-0?(\d+)-0?(\d+)/;

interface IDateOnly
{
    getType: () => 'DateOnly'
}

/**
 * A Date without time.
 */
export class DateOnly extends Date implements IDateOnly
{
    /**
     * Creates a new DateOnly with the current local date
     */
    constructor();
    /**
     * Creates a new DateOnly with:
     * - the timestamp in milliseconds if is a `number` but resetting the time to 00:00:00.000
     * - the ISO-8601 date if is a `string` but ignoring the time and the timezone.
     * - the same date and time that the parameter if is a `Date` but resetting the time to 00:00:00.000
     * @param value
     */
    constructor(value: number | string | Date);
    /**
     * Creates a new DateOnly.
     * @param year The full year designation is required for cross-century date accuracy. If year is between 0 and 99 is used, then year is assumed to be 1900 + year.
     * @param monthIndex The month as a number between 0 and 11 (January to December).
     * @param date The date as a number between 1 and 31. By default 1
     */
    constructor(year: number, monthIndex: number, date?: number);
    constructor(value?: number | string | Date, monthIndex?: number, date?: number)
    {
        if (isNumber(value) && isNumber(monthIndex))
        {
            super(value, monthIndex, date ?? 1);
        }
        else if (isString(value))
        {
            const result = dateOnlyRegex.exec(value);
            if (result === null || result.length !== 4)
            {
                throw new Error(`Invalid date: '${value}' for \`DateOnly\``);
            }

            const year = Number(result[1]);
            const month = Number(result[2]) - 1;
            const day = Number(result[3]);

            super(year, month, day);
        }
        else if (value !== undefined)
        {
            super(value);
        }
        else
        {
            super();
        }

        this.setHours(0, 0, 0, 0);

        // FIXME: https://github.com/storybookjs/storybook/issues/16168
        // https://github.com/storybookjs/storybook/issues/12019
        this.getType = this.getType = () => 'DateOnly';
    }

    public getType(): 'DateOnly'
    {
        return 'DateOnly';
    }

    /**
     * Format to JSON.
     * FIXME: Dont send T00:00:00.000Z, send => yyyy-MM-dd
     */
    toJSON(key?: any): string
    {
        const year = String(this.getFullYear()).padStart(4, '0');
        const month = String(this.getMonth() + 1).padStart(2, '0');
        const day = String(this.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00.000Z`;
    }

    toString(): string
    {
        return this.toJSON();
    }

    toUTCString(): string
    {
        return this.toJSON();
    }

    getUTCDate(): number
    {
        return this.getDate();
    }

    getUTCDay(): number
    {
        return this.getDay();
    }

    getUTCFullYear(): number
    {
        return this.getFullYear();
    }

    getUTCHours(): number
    {
        return this.getHours();
    }

    getUTCMilliseconds(): number
    {
        return this.getMilliseconds();
    }

    getUTCMinutes(): number
    {
        return this.getMinutes();
    }

    getUTCMonth(): number
    {
        return this.getMonth();
    }

    getUTCSeconds(): number
    {
        return this.getSeconds();
    }

}

interface IDateTime
{
    getType: () => 'DateTime'
}

/**
 * A Date with date and time in local timezone
 */
export class DateTime extends Date implements IDateTime
{
    /**
     * Creates a new DateTime with the current local date & time
     */
    constructor();
    /**
     * Creates a new DateTime with:
     * - the timestamp in milliseconds if is a `number`
     * - the ISO-8601 date if is a `string`
     * - the same date and time that the parameter if is a `Date`
     * @param value
     */
    constructor(value: number | string | Date);
    /**
     * Creates a new DateTime.
     * @param year The full year designation is required for cross-century date accuracy. If year is between 0 and 99 is used, then year is assumed to be 1900 + year.
     * @param monthIndex The month as a number between 0 and 11 (January to December).
     * @param date The date as a number between 1 and 31. By default 1.
     * @param hours Must be supplied if minutes is supplied. A number from 0 to 23 (midnight to 11pm) that specifies the hour. By default 0.
     * @param minutes Must be supplied if seconds is supplied. A number from 0 to 59 that specifies the minutes. By default 0.
     * @param seconds Must be supplied if milliseconds is supplied. A number from 0 to 59 that specifies the seconds. By default 0.
     * @param ms A number from 0 to 999 that specifies the milliseconds. By default 0.
     */
    constructor(year: number, monthIndex: number, date?: number, hours?: number, minutes?: number, seconds?: number, ms?: number);
    constructor(value?: number | string | Date, monthIndex?: number, date?: number, hours?: number, minutes?: number, seconds?: number, ms?: number)
    {
        if (isNumber(value) && isNumber(monthIndex))
        {
            super(value, monthIndex, date ?? 1, hours ?? 0, minutes ?? 0, seconds ?? 0, ms ?? 0);
        }
        else if (value !== undefined)
        {
            super(value);
        }
        else
        {
            super();
        }

        // FIXME: https:// Github.com/storybookjs/storybook/issues/16168
        // https://github.com/storybookjs/storybook/issues/12019
        this.getType = () => 'DateTime';
    }

    public getType(): 'DateTime'
    {
        return 'DateTime';
    }
}