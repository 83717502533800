import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { FicherosCargadosDTO } from './I000000DTO';
import { BasicoChartWidget } from './Widgets/BasicoChartWidgetComponent';

interface IGraficaFicherosChartProps {
    ficheros: Array<FicherosCargadosDTO>;
}

export const FicherosCargadosChart: VoidFunctionComponent<IGraficaFicherosChartProps> = ({ ficheros }) =>
{
    const { t } = useTranslation(['/FMT/I/I00/I000000/I000000'], { useSuspense: false });
    const navigate = useNavigate();

    const ficherosSinDatosVehiculos = ficheros.filter(e => e.ficherosVehiculos && e.tipoCoreValorResultado === 'Bloques sin datos');
    const ficherosErroneosVehiculos = ficheros.filter(e => e.ficherosVehiculos
        && (e.tipoCoreValorResultado === 'KO' ||
            e.tipoCoreValorResultado === 'Firma digital errónea' ||
            e.tipoCoreValorResultado === 'Error al tratar fichero'
        ));

    const ficherosSinDatosConductores = ficheros.filter(e => e.ficherosConductores
        && e.tipoCoreValorResultado === 'Bloques sin datos');
    const ficherosErroneosConductores = ficheros.filter(e => e.ficherosConductores
        && (e.tipoCoreValorResultado === 'KO' ||
            e.tipoCoreValorResultado === 'Firma digital errónea' ||
            e.tipoCoreValorResultado === 'Error al tratar fichero'
        ));

    const totalVehiculos = ficheros.filter(e => e.ficherosVehiculos).length;
    const totalConductores = ficheros.filter(e => e.ficherosConductores).length;

    return (
        <BasicoChartWidget
            title={t('widgetFicherosCargados.titulo')}
            className='ficheros'
            onClick={() => navigate('/F000000')}>
            <div className='grid-container'>
                <div className='row'>
                    <div className='column'>
                        <p className='contador'>{totalVehiculos}</p>
                        <h4>{t('widgetFicherosCargados.totalVehiculos')}</h4>
                    </div>
                    <div className='column'>
                        <p className='contador sin-datos'>{ficherosSinDatosVehiculos.length}</p>
                        <h4>{t('widgetFicherosCargados.bloqueSinDatos')}</h4>
                    </div>
                    <div className='column'>
                        <p className='contador erroneos'>{ficherosErroneosVehiculos.length}</p>
                        <h4>{t('widgetFicherosCargados.erroneos')}</h4>
                    </div>
                </div>
                <div className='row'>
                    <div className='column'>
                        <p className='contador'>{totalConductores}</p>
                        <h4>{t('widgetFicherosCargados.totalConductores')}</h4>
                    </div>
                    <div className='column'>
                        <p className='contador sin-datos'>{ficherosSinDatosConductores.length}</p>
                        <h4>{t('widgetFicherosCargados.bloqueSinDatos')}</h4>
                    </div>
                    <div className='column'>
                        <p className='contador erroneos'>{ficherosErroneosConductores.length}</p>
                        <h4>{t('widgetFicherosCargados.erroneos')}</h4>
                    </div>
                </div>
            </div>
        </BasicoChartWidget>
    );
};

FicherosCargadosChart.displayName = 'FicherosCargadosChart';
