import * as yup from 'yup';
import { BaseSchema, SchemaOf, TestContext, ValidationError } from 'yup';
import { Message } from 'yup/lib/types';

yup.setLocale({
    mixed: {
        required: () => ({ key: 'validation.mixed.required' }),
    },
    string:{
        length: ({ length }) => ({ key: 'validation.string.length', values: { length } }),
        min: ({ min }) => ({ key: 'validation.string.min', values: { min } }),
        max: ({ max }) => ({ key: 'validation.string.max', values: { max } }),
        email: () => ({ key: 'validation.string.email' }),
        url: () => ({ key: 'validation.string.url' }),
        lowercase: () => ({ key: 'validation.string.lowercase' }),
        uppercase: () => ({ key: 'validation.string.uppercase' }),
    },
    number: {
        min: ({ min }) => ({ key: 'validation.number.min', values: { min } }),
        max: ({ max }) => ({ key: 'validation.number.max', values: { max } }),
        lessThan: ({ less }) => ({ key: 'validation.number.lessThan', values: { less } }),
        moreThan: ({ more }) => ({ key: 'validation.number.moreThan', values: { more } }),
        positive: () => ({ key: 'validation.number.positive' }),
        negative: () => ({ key: 'validation.number.negative' }),
        integer: () => ({ key: 'validation.number.integer' }),
    },
    date: {
        min: ({ min }) => ({ key: 'validation.date.min', values: { min } }),
        max: ({ max }) => ({ key: 'validation.date.max', values: { max } }),
    }
});

yup.addMethod<SchemaOf<Record<string, any>>>(yup.object, 'atLeastOneOf', function(this, list: Array<keyof Record<string, any>>, validator: yup.SchemaOf<any>, message?: Message)
{
    return this.test(
        'atLeastOneOf',
        message || `Tienes que tener seleccionada alguna de estas keys ${list}`,
        function(this: TestContext<object>, value: Record<string, any>): value is Record<string, any>
        {
            return value == null || list.some(f => validator.isValidSync(value[f]));
        }
    );
});

yup.addMethod<SchemaOf<Record<string, any>>>(yup.object, 'onlyOneOf', function(this, list: Array<keyof Record<string, any>>, validator: yup.SchemaOf<any>, message?: Message)
{
    return this.test(
        'onlyOneOf',
        message || `Solo puedes tener seleccionado una de estas propiedades ${list}`,
        function(this: TestContext<object>, value: Record<string, any>): value is Record<string, any>
        {
            return value == null || list.filter(f => validator.isValidSync(value[f])).length === 1;
        }
    );
});

[yup.mixed, yup.bool, yup.boolean, yup.date, yup.number, yup.string].forEach(schema =>
{
    yup.addMethod<BaseSchema>(schema, 'removeIfEmpty', function(this)
    {
        return this.transform((value: any, originalValue: any) =>
        {
            if (typeof originalValue === 'string' &&
                (originalValue.trim() === '' || originalValue.trim() === '*'))
            {
                return undefined;
            }
            else
            {
                return value;
            }
        });
    });

    yup.addMethod<BaseSchema>(schema, 'ifUndefined', function(this, defaultValue: any)
    {
        return this.transform((value: any, originalValue: any) =>
        {
            if (originalValue === undefined)
            {
                return defaultValue;
            }
            else
            {
                return value;
            }
        });
    });
});

yup.addMethod(yup.number, 'ifEmpty', function(this, defaultValue: number | null)
{
    return this.transform((value: any, originalValue: string | number | null) =>
    {
        if (typeof originalValue === 'string' &&
            (originalValue.trim() === '' || originalValue.trim() === '*'))
        {
            return defaultValue;
        }
        else
        {
            return value;
        }
    });
});

yup.addMethod(yup.boolean, 'ifEmpty', function(this, defaultValue: boolean | null)
{
    return this.transform((value: any, originalValue: string | null) =>
    {
        if (typeof originalValue === 'string' &&
            (originalValue.trim() === '' || originalValue.trim() === '*'))
        {
            return defaultValue;
        }
        else
        {
            return value;
        }
    });
});

yup.addMethod(yup.string, 'ifEmpty', function(this, defaultValue: string | null)
{
    return this.transform((value: any, originalValue: string | null) =>
    {
        if (typeof originalValue === 'string' &&
            (originalValue.trim() === '' || originalValue.trim() === '*'))
        {
            return defaultValue;
        }
        else
        {
            return value;
        }
    });
});

yup.addMethod(yup.string, 'nss', function(this, message?: Message)
{
    return this.test(
        'nss',
        message || 'El NSS no es válido',
        function(this: TestContext<object>, value: string | undefined): boolean | ValidationError | Promise<boolean | ValidationError>
        {
            let result = false;

            if (value !== undefined && value.length >= 14)
            {
                let numProvincia: number = 0;
                let numNSS: number = 0;
                let numControl: number = 0;

                if (value.indexOf('/') >= 0)
                {
                    const nuevoNSS_1 = value.split('/');
                    numProvincia = parseInt(nuevoNSS_1[0]);
                    numNSS = parseInt(nuevoNSS_1[1]);
                    numControl = parseInt(nuevoNSS_1[2]);
                }
                else
                {
                    numProvincia = parseInt(value.substr(0, 2));
                    numNSS = parseInt(value.substr(2, 8));
                    numControl = parseInt(value.substr(10, 2));
                }

                let aux = 0;
                if (numNSS < 10000000)
                {
                    aux = numNSS + numProvincia * 10000000;
                }
                else
                {
                    aux = parseInt(numProvincia.toString() + numNSS.toString());
                }

                if (numControl !== (aux % 97))
                {
                    result = false;
                }
                else
                {
                    result = true;
                }
            }
            return result;
        }
    );
});

yup.addMethod(yup.string, 'nif', function(this, message?: Message)
{
    return this.test(
        'nif',
        message || 'El NIF no es válido',
        function(this: TestContext<object>, value: string | undefined): boolean | ValidationError | Promise<boolean | ValidationError>
        {
            if (value === undefined || value.length !== 9)
            {
                return false;
            }

            const inicio = value.substr(0, 1);
            var medio = value.substr(1, value.length - 1);
            let numero : number | null = null;
            const letr = value.substr(value.length - 1, 1);
            const valorNumerioco = Number(inicio);

            if (!isNaN(valorNumerioco))
            {
                numero = parseInt(value.substr(0, value.length - 1));
            }
            else
            {
                if (inicio.toUpperCase() === 'X')
                {
                    numero = parseInt(`0${medio}`);
                }
                else if (inicio.toUpperCase() === 'Y')
                {
                    numero = parseInt(`1${medio}`);
                }
                else if (inicio.toUpperCase() === 'Z')
                {
                    numero = parseInt(`2${medio}`);
                }
                else
                {
                    return false;
                }
            }

            numero = numero % 23;
            let letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero + 1);

            if (letra !== letr.toUpperCase())
            {
                return false;
            }

            return true;
        }
    );
});