import { useContext } from 'react';

import { InnerBreadcrumbContext } from './InnerBreadcrumbProvider';

/**
 * Hook for getting the inner breadcrumb based on context.
 * @returns string | Array<string>
 */
export const useInnerBreadcrumb = () =>
{
    const { breadcrumb } = useContext(InnerBreadcrumbContext);
    return breadcrumb;
};

/**
 * Hook for setting the inner breadcrumb based on context.
 * @returns (breadcrumb: string | Array<string>) => {}
 */
export const useInnerBreadcrumbSetter = () =>
{
    const { setBreadcrumb } = useContext(InnerBreadcrumbContext);
    return setBreadcrumb;
};