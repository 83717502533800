import { createContext, PropsWithChildren } from 'react';

import { TransactionPermissionDTO } from 'Services/Transaction/ITransactionService';

export const TransactionPermissionContext = createContext<TransactionPermissionDTO | null>(null);

export const TransactionPermissionProvider = ({
    read,
    modify,
    allowedTransactionOperationsKeys,
    allowAllTransactionOperations,
    children
} : PropsWithChildren<TransactionPermissionDTO>) =>
{
    return <TransactionPermissionContext.Provider value={{ read, modify, allowedTransactionOperationsKeys, allowAllTransactionOperations }}>
        { children }
    </TransactionPermissionContext.Provider>;
};