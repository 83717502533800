import { IconSolid } from 'Theme/Icons';
import { Component, ErrorInfo } from 'react';

import { IErrorBoundaryState } from './IErrorBoundaryState';

import './ErrorBoundaryTheme.scss';

export class ErrorBoundary extends Component<{}, IErrorBoundaryState>
{
    constructor(props: {})
    {
        super(props);
        this.state = { error: null, errorInfo: null };
        this.globalErrorHandler = this.globalErrorHandler.bind(this); // Bind function once
    }

    componentDidMount()
    {
        window.addEventListener('unhandledrejection', this.globalErrorHandler);
        window.addEventListener('error', this.globalErrorHandler);
    }

    componentWillUnmount()
    {
        window.removeEventListener('unhandledrejection', this.globalErrorHandler);
        window.removeEventListener('error', this.globalErrorHandler);
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo)
    {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }

    globalErrorHandler(event: Event)
    {
        console.error('Some Error happens: ', event);
        if (event instanceof PromiseRejectionEvent)
        {
            this.setState({
                error: new Error(event.reason),
                errorInfo: event.reason
            });
        }
        else
        {
            this.setState({
                error: new Error('unknown error'),
                errorInfo: null
            });
        }
    }

    render()
    {
        if (this.state.errorInfo)
        {
            // Error path
            return (
                <div className="errorPage">
                    <h1>¡Vaya, algo ha fallado!</h1>
                    <h2 className={IconSolid.bug}>
                    </h2>
                    <p className="msg">
                        Si quieres, ponnos un ticket contándonos que estabas haciendo e intentaremos solucionar el error lo antes posible.
                    </p>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}