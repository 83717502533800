import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { InnerBreadcrumbProvider } from 'Components/Core/Layout/NavTopbar/Breadcrumb/InnerBreadcrumbProvider';
import { TransactionProvider } from 'Components/Core/Layout/Transaction/TransactionProvider';
import { LoaderProvider } from 'Components/Core/Loaders/LoaderProvider';

import { ConfirmationDialogs } from '../Dialog/DialogComponent';
import { MessageDialogs } from '../Dialog/MessageDialogComponent';
import { Notifications } from '../Notification/NotificationComponent';
import { NotificationsProvider } from '../Notification/NotificationsProvider';
import { useNavigationType } from '../Routes/Hooks/RouterHook';

import { ILayoutProps } from './ILayoutProps';
import { NavSidebar } from './NavSidebar/NavSidebarComponent';
import { NavTopbar } from './NavTopbar/NavTopbarComponent';
import { UserSidebar } from './UserSidebar/UserSidebarComponent';

import './LayoutTheme.scss';

/**
 * Main layout component.
 * @param {ILayoutProps} props
 * @returns Layout
 */
export const Layout: FunctionComponent<ILayoutProps> = ({ children }: ILayoutProps) =>
{
    // #region Panel de usuario
    const [userSidebarOpen, setUserSidebarOpen] = useState<boolean>(false);

    const toggleUserSidebar = () =>
    {
        setUserSidebarOpen(previous => !previous);
    };
    // #endregion

    // #region Historial de navegación

    const closeUserSidebar = () => setUserSidebarOpen(false);

    const { pathname } = useLocation();
    const navigationType =  useNavigationType();

    useEffect(() =>
    {
        if (process.env.NODE_ENV === 'development')
        {
            console.log(`Location change to: ${pathname} with type: ${navigationType}`);
        }
        // If there's navigation, we empty & close the finder
        if (navigationType === 'PUSH'
            || navigationType === 'POP')
        {
            closeUserSidebar();
        }
    }, [pathname, navigationType]);
    // #endregion

    // #region Lanzamiento
    return (
        <>
            <NotificationsProvider>
                <div className="wrapper">
                    <TransactionProvider>
                        <UserSidebar
                            open={userSidebarOpen}
                            toggleUserSidebar={toggleUserSidebar}/>
                        <div className="content">
                            <InnerBreadcrumbProvider>
                                <header>
                                    <NavTopbar
                                        {...{
                                            toggleUserSidebar
                                        }} />
                                </header>
                                <LoaderProvider>
                                    <main>
                                        <NavSidebar />
                                        {children}
                                    </main>
                                </LoaderProvider>
                            </InnerBreadcrumbProvider>
                        </div>
                    </TransactionProvider>
                </div>
                <div className={`overlay ${userSidebarOpen ? 'active' : ''}`}
                    onClick={closeUserSidebar}>
                </div>
                <MessageDialogs />
                <ConfirmationDialogs />
                <Notifications/>
            </NotificationsProvider>
        </>
    );
    // #endregion
};

Layout.displayName = 'Layout';