import { FunctionComponent } from 'react';
import { Route } from 'react-router';

import { withTranslation } from 'Libraries/i18next/I18nextConfig';

import { PrivateRoute } from 'Components/Core/Routes/PrivateRoute';
import { I000000 } from 'Components/FMT/I/I00/I000000/I000000';
import { I000404 } from 'Components/FMT/I/I00/I000404/I000404';
import { generateRouter } from 'Components/FMT/Router';

const context = require.context('./', true, /([a-zA-Z][0-9]{6,6}\.tsx)$/, 'lazy');
const TransactionRouter = generateRouter(context, 'I00');

export const I00Router: FunctionComponent = () =>
{
    return (
        <>
            <PrivateRoute exact path='/' component={withTranslation(I000000)} />
            <Route exact path='/I000404' component={withTranslation(I000404)} />
            <TransactionRouter exclude={['I000000', 'I000404']}/>
        </>
    );
};

I00Router.displayName = 'I00Router';