import { IconSolid } from 'Theme/Icons';
import { FunctionComponent, VoidFunctionComponent } from 'react';

import { Dialog } from './DialogComponent';
import { IMessageDialogProps } from './IDialogProps';

import './MessageDialogTheme.scss';

/**
 * Message dialog core component.
 * @param {IMessageDialogProps} props
 * @returns MessageDialog
 */
export const MessageDialog: FunctionComponent<IMessageDialogProps> = ({
    actions,
    className,
    text,
    title = 'TMS',
    type = 'standard',
    engine,
    width,
    height
}) =>
{
    const _messages = Array.isArray(text) ?
        text
        : (text !== '' ?
            [text]
            : []);

    const _width = width ?? (Array.isArray(text) && _messages.length > 1 ? 'medium' : 'small');
    const _height = height ?? (Array.isArray(text) && _messages.length > 1 ? 'medium' : 'small');

    return (
        <Dialog
            title={<>
                {type === 'warning' && <i className={IconSolid.exclamationTriangle}></i>}
                {type === 'error' && <i className={IconSolid.timesCircle}></i>}
                {title}
            </>}
            engine={engine}
            className={`message ${className ? className : ''} ${(type !== 'standard') ? type : ''}`}
            actions={actions}
            width={_width}
            height={_height}>
            {
                _messages.map((element, index) => (
                    <p key={index}>
                        {element}
                    </p>
                ))
            }
        </Dialog>
    );
};

/**
 * Messages Dialogs center where all <MessageDialog /> are added.
 *
 * @param {{ id?: string }} props
 */
export const MessageDialogs: VoidFunctionComponent<{ id?: string; }> = ({ id = 'dialogs-message-id' }: { id?: string; }) =>
{
    return <div id={id}></div>;
};