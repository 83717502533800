import jwt_decode from 'jwt-decode';

import { ResponseDTO, ResponseStatusEnum } from '../DTOs/IResponse';

import { AccessResultDTO, AuthDTO, IAuthenticationService, TokenDTO } from './IAuthenticationService';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export class AuthenticationService implements IAuthenticationService
{
    private _currentUser : AuthDTO | null = null;

    private keyCurrentUserName: string = 'currentUser';

    private keyJwtToken: string = 'jwt_';

    constructor()
    {
        const token = localStorage.getItem(`${this.keyJwtToken}_${localStorage.getItem(this.keyCurrentUserName)}`);

        if (token != null)
        {
            this._currentUser = JSON.parse(token);
        }
        else
        {
            this._currentUser = null;
        }
    }

    get currentUser() : AuthDTO | null { return this._currentUser; }

    async login(userName: string, password: string) : Promise<AccessResultDTO>
    {
        // Get the JWT Token already stored by this userName in order to try to skip the 2FA step.
        const jwtTokenLocalStorage = localStorage.getItem(`${this.keyJwtToken}_${userName}`);
        const authDTOLocalStorage: AuthDTO | null = jwtTokenLocalStorage != null ? JSON.parse(jwtTokenLocalStorage) : null;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authDTOLocalStorage?.token}` },
            body: JSON.stringify({ userName, password })
        };

        const response = await fetch(`${baseUrl}I010000/Login`, requestOptions);

        return await this.processLoginResult(response);
    }

    async loginQR(qrCode: string): Promise<AccessResultDTO>
    {
        // Get the JWT Token already stored by this qrCode in order to try to skip the 2FA step.
        const jwtTokenLocalStorage = localStorage.getItem(`${this.keyJwtToken}_${qrCode}`);
        const authDTOLocalStorage: AuthDTO | null = jwtTokenLocalStorage != null ? JSON.parse(jwtTokenLocalStorage) : null;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authDTOLocalStorage?.token}` },
            body: JSON.stringify({ userStamp: qrCode })
        };

        const response = await fetch(`${baseUrl}I010000/Login`, requestOptions);

        return await this.processLoginResult(response);
    }

    /**
     * Process the login result checking the user token.
     * If the token is a 2FA token, we need to call the `twoFactor` method.
     * @param {Response} response
     * @returns
     */
    private async processLoginResult(response: Response)
    {
        const json = await response.json() as ResponseDTO<AccessResultDTO>;

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        if (!json.data.enable2FA)
        {
            // Store user details and jwt token in local storage to keep user logged in between page refreshes
            this.storeCurrentUser(json.data);
        }

        return json.data;
    }

    async twoFactor(otp: string, token: string) : Promise<AuthDTO>
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
            body: JSON.stringify({ otp })
        };

        const response = await fetch(`${baseUrl}I010000/OTP`, requestOptions);
        const json =  await response.json() as ResponseDTO<AccessResultDTO>;

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        // When the user is logged, we must crete a new key in local storage in order to get the last user
        this.storeCurrentUser(json.data);

        return json.data;
    }

    async setNewPassword(password: string, repeatedPassword: string) : Promise<AuthDTO>
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.currentUser?.token}` },
            body: JSON.stringify({ password, repeatedPassword })
        };

        const response = await fetch(`${baseUrl}I010000/SetNewPassword`, requestOptions);
        const json =  await response.json() as ResponseDTO<AccessResultDTO>;

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        // When the user is logged, we must crete a new key in local storage in order to get the last user
        this.storeCurrentUser(json.data);

        return json.data;
    }

    async refreshToken(): Promise<AccessResultDTO>
    {
        if (!this.isLogged())
        {
            throw new Error('Not exists any user logged, use `AuthenticationService#login` first');
        }

        if (this.isCurrentTokenExpired())
        {
            throw new Error('The current user have the token expired, the `refreshToken()` method must be called before the token is expired.');
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.currentUser?.token}` },
        };

        const response = await fetch(`${baseUrl}I010000/RefreshJWT`, requestOptions);
        const json =  await response.json() as ResponseDTO<AccessResultDTO>;

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        this.storeCurrentUser(json.data);

        return json.data;
    }

    isLogged() : boolean
    {
        return this.currentUser != null;
    }

    modifyPassword(): boolean
    {
        return this.currentUser?.modifyPassword || false;
    }

    isEnable2FA(): boolean
    {
        return this.currentUser?.enable2FA || false;
    }

    /**
     * Check if the user logged has the token expired
     *
     * @return {boolean}
     */
    isCurrentTokenExpired(): boolean
    {
        const token = this.geCurrentToken();
        return this.isTokenExpired(token);
    }

    /**
     * Check if the token parameter is expired
     * @param {TokenDTO | null} token
     * @return {boolean}
     */
    isTokenExpired(token: TokenDTO | null): boolean
    {
        if (token)
        {
            const date = new Date(0);
            date.setUTCSeconds(token.exp);
            return date < new Date();
        }

        return true;
    }

    geCurrentToken(): TokenDTO | null
    {
        return this.getToken(this.currentUser?.token);
    }

    getToken(token: string | null | undefined): TokenDTO | null
    {
        if (token)
        {
            return jwt_decode<TokenDTO>(token);
        }
        else
        {
            return null;
        }
    }

    getAuthHeader() : { Authorization: string} | {}
    {
        if (this.currentUser && this.currentUser.token)
        {
            return { Authorization: `Bearer ${this.currentUser.token}` };
        }
        else
        {
            return {};
        }
    }

    logout()
    {
        if (this.currentUser !== null)
        {
            this._currentUser = null;
            localStorage.removeItem(this.keyCurrentUserName);
            // Dont remove the keyJwtToken to avoid 2FA if not expired the next login with this userName.
        }
    }

    /**
     * Store the `AuthDTO` in the local storage and in memory
     * @private
     * @param {AuthDTO} user
     */
    private storeCurrentUser(user: AuthDTO)
    {
        localStorage.setItem(`${this.keyJwtToken}_${user.userName}`, JSON.stringify(user));
        localStorage.setItem(`${this.keyCurrentUserName}`, user.userName);
        this._currentUser = user;
    }
}