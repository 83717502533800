import { createContext, Dispatch, FunctionComponent, SetStateAction, useState } from 'react';

export const BlockedSetterContext = createContext<Dispatch<SetStateAction<boolean>>>(() => {});
export const BlockedGetterContext = createContext<boolean>(false);

export const BlockedProvider: FunctionComponent = ({ children }) =>
{
    const [blocked, setBlocked] = useState<boolean>(false);

    return (
        // Double context to avoid rerenders =>
        // https://medium.com/@bhavyasaggi/how-did-i-re-render-sharing-state-through-react-context-f271d5890a7b
        // https://codesandbox.io/s/dual-context-props-zwk6d?from-embed=&file=/src/ContextProvider.js
        // https://codesandbox.io/s/dual-context-props-forked-f7mpjc <= test
        <BlockedSetterContext.Provider value={setBlocked}>
            <BlockedGetterContext.Provider value={blocked}>
                {children}
            </BlockedGetterContext.Provider>
        </BlockedSetterContext.Provider>
    );
};

BlockedProvider.displayName = 'BlockedProvider';