import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import 'hammerjs';
import { ReactElement, ReactNode } from 'react';

import './BasicoChartWidgetTheme.scss';

interface IBasicoChartWidgetProps {
    title?: string;
    className?: string;
    children?: ReactNode;
    onClick?: () => void;
}

export function BasicoChartWidget({
    title,
    className = '',
    children,
    onClick
}: IBasicoChartWidgetProps): ReactElement<IBasicoChartWidgetProps>
{
    return (
        <Card
            orientation='horizontal'
            className={`basicoChartWidget ${onClick ? 'clickable' : ''} ${className}`}
            {...onClick && { onClick }}>
            <CardBody>
                {title && <CardTitle>{title}</CardTitle>}
                <div className={`contentWrapper${children ? ' withAdded' : ''}`}>
                    {children &&
                    <div className='addedContent'>
                        {children}
                    </div>
                    }
                </div>
            </CardBody>
        </Card>
    );
};

BasicoChartWidget.displayName = 'BasicoChartWidget';
