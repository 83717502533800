import { FunctionComponent } from 'react';

import { S00Router } from './S00/S00Router';

export const SRouter: FunctionComponent = () =>
{
    return (
        <>
            <S00Router />
        </>
    );
};

SRouter.displayName = 'SRouter';