import { ResponseDTO } from 'Services/DTOs/IResponse';
import { appendQueryParams, isFormData } from 'Services/Utils/Utils';

import { IBackendFileService, IBackendFileServiceFactory } from './IBackendFileService';
import { IFetchAuthService } from './IFetchAuthService';
import { FormBody, IHttpService, QueryParams } from './IHttpService';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export class BackendFileServiceFactory implements IBackendFileServiceFactory
{
    #httpService: IHttpService;
    #fetchSecureService: IFetchAuthService;

    constructor(fetchSecureService: IFetchAuthService, httpService: IHttpService)
    {
        this.#fetchSecureService = fetchSecureService;
        this.#httpService = httpService;
    }

    get(transactionId: string) : IBackendFileService
    {
        return new BackendFileServiceImpl(transactionId, this.#fetchSecureService, this.#httpService);
    }
}

class BackendFileServiceImpl implements IBackendFileService
{
    #transactionId: string;
    #fetchService: IFetchAuthService;
    #httpService: IHttpService;

    constructor(transactionId: string, fetchService: IFetchAuthService, httpService: IHttpService)
    {
        this.#transactionId = transactionId;
        this.#fetchService = fetchService;
        this.#httpService = httpService;
    }

    async postFile<T>(url: string, body?: FormBody | FormData, queryParams?: QueryParams): Promise<T>
    {
        const result = await this.#httpService.post<ResponseDTO<T>>(`${this.#transactionId}/${url}`, body, queryParams);
        return result.data;
    }

    async getFileUrl(url: string, body?: FormBody | FormData, queryParams?: QueryParams): Promise<string>
    {
        const finalUrl = this.getUrl(url, queryParams);
        const requestInit = this.getRequestInit(body);

        const response = await this.#fetchService.fetch(finalUrl, requestInit);
        const blob = await response.blob();
        return (window.URL || window.webkitURL).createObjectURL(blob);
    }

    async downloadFile(url: string, body?: FormBody | FormData, queryParams?: QueryParams): Promise<void>
    {
        const finalUrl = this.getUrl(url, queryParams);
        const requestInit = this.getRequestInit(body);

        return await this.#fetchService.download(finalUrl, requestInit);
    }

    async openFile(url: string, body?: FormBody | FormData, queryParams?: QueryParams): Promise<void>
    {
        const finalUrl = this.getUrl(url, queryParams);
        const requestInit = this.getRequestInit(body);

        return await this.#fetchService.open(finalUrl, requestInit);
    }

    private getUrl(url: string, queryParams?: QueryParams): string
    {
        if (queryParams)
        {
            return appendQueryParams(`${baseUrl}${this.#transactionId}/${url}`, queryParams);
        }
        else
        {
            return `${baseUrl}${this.#transactionId}/${url}`;
        }
    }

    private getRequestInit(body?: FormBody | FormData)
    {
        if (body && isFormData(body))
        {
            return { method: 'POST', body };
        }
        else
        {
            return { method: 'POST', body: body ? JSON.stringify(body) : undefined, headers: { 'accept': 'application/json', 'content-type': 'application/json' } };
        }
    }
}