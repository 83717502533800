import { useState, VoidFunctionComponent } from 'react';
import { WithTranslation } from 'react-i18next';

import { useGet } from 'Components/Core/Hooks/GetHook';
import { CardDeck } from 'Components/Core/LaunchCard/LaunchCardComponent';
import { Transaction } from 'Components/Core/Layout/Transaction/TransactionComponent';
import { Loading } from 'Components/Core/Loaders/LoadingComponent';
import { useBackendFormService } from 'Components/Core/Services/Hooks/BackendFormHook';

import { ConductoresDescargaChart } from './ConductoresDescargaChartComponent';
import { FicherosCargadosChart } from './FicherosCargadosChartComponent';
import { RevisionesPendientesDTO, DescargaConductorDTO, DescargaVehiculoDTO, LicenciaDTO, TarjetaConductorDTO, FicherosCargadosDTO  } from './I000000DTO';
import { LicenciasChart } from './LicenciasChartComponent';
import { TarjetasConductores } from './TarjetasConductoresComponent';
import { VehiculosDescargaChart } from './VehiculosDescargaChartComponent';
import { VehiculosPendientesRevisionChart } from './VehiculosPendientesRevisionChartComponent';

import './I000000Theme.scss';

const transactionId = 'I000000';

interface IContenidoDTO {
    vehiculos: Array<DescargaVehiculoDTO>;
    conductores: Array<DescargaConductorDTO>;
    licencias: Array<LicenciaDTO>;
    revisiones: Array<RevisionesPendientesDTO>
    tarjetasConductores: Array<TarjetaConductorDTO>
    ficheros: Array<FicherosCargadosDTO>;
    cargando: boolean;
}

/**
 * I000000: Transacción de Home/Inicio.
 * @author Diego Crenes Carrera, diego.crenes[at]airgrup.com
 */
export const I000000: VoidFunctionComponent<WithTranslation> = ({ t }: WithTranslation) =>
{
    // #region Hooks
    const backendFormService = useBackendFormService(transactionId);
    const [contenido, setContenido] = useState<IContenidoDTO>({
        vehiculos: [],
        conductores: [],
        licencias: [],
        revisiones: [],
        tarjetasConductores: [],
        ficheros: [],
        cargando: true
    });
    // #endregion

    // Primera llamada asíncrona al back para cargar automáticamente la lista de funciones
    useGet(async (mount) =>
    {
        if (mount.current)
        {
            const resultadoVehiculos = await backendFormService.get<Array<DescargaVehiculoDTO>>('ObtenerDescargaVehiculoDTO');
            const resultadoConductores = await backendFormService.get<Array<DescargaConductorDTO>>('ObtenerDescargaConductorDTO');
            const resultadoLicencias = await backendFormService.get<Array<LicenciaDTO>>('ObtenerLicenciaDTO');
            const resultadoRevisiones = await backendFormService.get<Array<RevisionesPendientesDTO>>('ObtenerRevisionesPendientesDTO');
            const resultadoTarjetasConductores = await backendFormService.get<Array<TarjetaConductorDTO>>('ObtenerTarjetaConduntorDTO');
            const resultadoFicheros = await backendFormService.get<Array<FicherosCargadosDTO>>('ObtenerFicherosCargadosDTO');
            setContenido({
                vehiculos: resultadoVehiculos,
                conductores: resultadoConductores,
                licencias: resultadoLicencias,
                revisiones: resultadoRevisiones,
                tarjetasConductores: resultadoTarjetasConductores,
                ficheros: resultadoFicheros,
                cargando: false
            });
        }
    });

    return (
        <>
            {contenido.cargando && <Loading />}
            {!contenido.cargando &&
                <Transaction id={transactionId} title={t('titulo')} cardMode={false}>
                    <CardDeck>
                        <VehiculosDescargaChart vehiculos={contenido.vehiculos} />
                        <ConductoresDescargaChart conductores={contenido.conductores} />
                        <LicenciasChart licencias={contenido.licencias} />
                        <TarjetasConductores tarjetasConductores={contenido.tarjetasConductores} />
                        <VehiculosPendientesRevisionChart revisiones={contenido.revisiones} />
                        <FicherosCargadosChart ficheros={contenido.ficheros} />
                    </CardDeck>
                </Transaction>
            }
        </>
    );
    // #endregion
};

I000000.displayName = transactionId;