import { ITransactionService } from 'Services/Transaction/ITransactionService';

import { useConstant } from 'Components/Core/Hooks/ConstantHook';

import { ServicesTypes, useService } from '../ServiceProvider';

/**
 * Get the `ITransactionService` for a transactionId
 * @param transactionId - the transaction id
 * @returns {ITransactionService}
 */
export const useTransactionService = (transactionId: string): ITransactionService =>
{
    const transactionServiceFactory = useService(ServicesTypes.ITransactionServiceFactory);
    return useConstant(() => transactionServiceFactory.get(transactionId));
};