import { DataSourceRequestState, toDataSourceRequest as kendoToDataSourceRequest, toDataSourceRequestString as kendoToDataSourceRequestString } from '@progress/kendo-data-query';
import { isArray, isDate } from 'lodash';

/**
 *
 * Kendo dont allow to use differents timezones in back and front, and recommend to always use UTC.
 * This method wrap the original kendo `toDataSourceRequest` and transform the dates to UTC.
 *
 * @see https://www.telerik.com/forums/grid-date-filter-value-missing-time-zone
 * @see https://docs.telerik.com/aspnet-mvc/html-helpers/data-management/grid/how-to/editing/utc-time-on-both-server-and-client
 * @see https://github.com/telerik/kendo-ui-core/issues/5306
 *
 * @param state
 * @returns
 */
export const toDataSourceRequest = (state : DataSourceRequestState): any =>
    kendoToDataSourceRequest(fixUTCDates(state));

/**
 *
 * Kendo dont allow to use differents timezones in back and front, and recommend to always use UTC.
 * This method wrap the original kendo `toDataSourceRequestString` and transform the dates to UTC before parsing it.
 *
 * @see https://www.telerik.com/forums/grid-date-filter-value-missing-time-zone
 * @see https://docs.telerik.com/aspnet-mvc/html-helpers/data-management/grid/how-to/editing/utc-time-on-both-server-and-client
 * @see https://github.com/telerik/kendo-ui-core/issues/5306
 *
 * @param state
 * @returns
 */
export const toDataSourceRequestString = (state : DataSourceRequestState): string =>
    kendoToDataSourceRequestString(fixUTCDates(state));

/**
 * Create a new local date with the value of the UTC Date passed by params.
 * ```ts
 * const date = new Date('2015-05-04T22:00:00');
 * console.log(date.toString()); // Local date => 'Mon May 04 2015 22:00:00 GMT+0200'
 * console.log(date.tooISOString()); // UTC date => '2015-05-04T20:00:00.000Z'
 *
 * // Transform to UTC local
 * const newDate = toUTCLocal(date);
 * console.log(newDate.toString()) // New local date => 'Mon May 04 2015 20:00:00 GMT+0200'
 * console.log(newDate.tooISOString()) // New UTC date => '2015-05-04T18:00:00.000Z'
 * ```
 */
const toUTCLocal = (date: Date) =>
    new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
    );

/**
 * Fix Kendo issue working with dates.
 * @param object
 * @returns new copy with all dates fixed
 */
const fixUTCDates = (object: any) =>
{
    if (isDate(object))
    {
        // Transform the local date to UTC
        return toUTCLocal(object);
    }

    if (!object || !(object instanceof Object))
    {
        return object;
    }

    const newObject = isArray(object) ? [...object] : { ...object };

    for (const key in newObject) // Array or Object
    {
        const value = newObject[key];

        newObject[key] = fixUTCDates(value);
    }

    return newObject;
};