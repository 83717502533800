import { ComponentType, CSSProperties, ReactNode, useRef, useState } from 'react';
import { render } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { TransactionPermissionDTO } from 'Services/Transaction/ITransactionService';

import { Button } from 'Components/Core/Button/ButtonComponent';
import { useGet } from 'Components/Core/Hooks/GetHook';
import { TransactionPermissionProvider } from 'Components/Core/Layout/Transaction/TransactionPermissionProvider';
import { ILoader } from 'Components/Core/Loaders/ILoader';
import { IShowNotificationProps } from 'Components/Core/Notification/Hooks/NotificationHook';
import { useTransactionService } from 'Components/Core/Services/Hooks/TransactionHook';

import { Dialog } from '../DialogComponent';
import { IDialogProps, IMessageDialogProps } from '../IDialogProps';
import { MessageDialog } from '../MessageDialogComponent';

/**
 * Check the permission for the transactionId and set the permissions in the context.
 * @param {string} transactionId the transaction id.
 */
export const withDialogPermission = (transactionId: string) => <T, >(Component: ComponentType<T>) => (props: T & { children?: ReactNode; }) =>
{
    const transactionService = useTransactionService(transactionId);
    const [transactionPermission, setTransactionPermission] = useState<TransactionPermissionDTO & { loaded: boolean }>({
        read: false,
        modify: false,
        allowedTransactionOperationsKeys: [],
        allowAllTransactionOperations: false,
        loaded: false
    });

    useGet(async (mount) =>
    {
        const userPermission = await transactionService.getPermission();

        if (mount.current)
        {
            setTransactionPermission({ ...userPermission, loaded: true });
        }

        return () =>
        {
            // When unmounting, do something?
        };
    });

    return (
        <TransactionPermissionProvider {...transactionPermission}>
            { transactionPermission.loaded && <Component {...props}/> }
        </TransactionPermissionProvider>
    );
};

// #region Dialog Engine

export interface IDialogEngine
{
    /**
     * Show the component.
     * If already visible, do nothing.
     */
    show: () => void,
    /**
     * Hide the component.
     * If already not visibile, do nothing.
     */
    hide: () => void;
    /**
     * Get the state of the dialog visible or invisible.
     */
    isVisible: () => boolean;
    /**
     * @see ILoader.showLoader
     */
    showLoader: () => void;
    /**
     * @see ILoader.hideLoader
     */
    hideLoader: () => void;
    /**
     * Set the state of the dialog blocked layer.
     */
    setBlocked: (visibility: boolean) => void;
    /**
     * Show a notification
     * @see {useNotification}
     */
    showNotification: (props?: IShowNotificationProps) => void;
}

export interface DialogEngine extends IDialogEngine
{
    /**
     * Allow to configure the dialog component with engine.
     */
    configure: (props: ConfigurationDialogEngine) => void;
}

interface ConfigurationDialogEngine
{
    /**
     * Set the showNotification function
     */
    showNotification: (props?: IShowNotificationProps) => void;

    /**
     * Set the loader function
     */
    loader: ILoader

    /**
     * Set the blocked function
     */
    setBlocked: (visibility: boolean) => void;
}

/**
 * Configure a engine for the `<Dialog>` component.
 * With this engine we can use to the `DialogEngine` API.
 *
 * @param {boolean} init the default status of the dialog (visible or invisible). Default to `false`
 * @returns {IDialogEngine} Engine to attach to a `<Dialog />` component.
 */
export const useDialogEngine = (init: boolean = false) : IDialogEngine =>
{
    const [visible, setVisible] = useState(init);

    const showNotificationRef = useRef<((props?: IShowNotificationProps) => void)>();
    const loaderRef = useRef<ILoader>();
    const setBlockedRef = useRef<((blocked: boolean) => void)>();

    const result : DialogEngine = {
        show: () =>
        {
            setVisible(true);
        },
        hide: () =>
        {
            setVisible(false);
        },
        isVisible: () => visible,
        showLoader: () => loaderRef.current?.showLoader(),
        hideLoader: () => loaderRef.current?.hideLoader(),
        setBlocked: (blocked) =>
        {
            setBlockedRef.current?.(blocked);
        },
        showNotification: (props?: IShowNotificationProps) =>
        {
            showNotificationRef.current?.(props);
        },
        configure: (props: ConfigurationDialogEngine) =>
        {
            showNotificationRef.current = props.showNotification;
            loaderRef.current = props.loader;
        }
    };

    return result;
};

// #endregion

// #region Message Dialog

export type IShowMessageDialogProps = Partial<Omit<IMessageDialogProps, 'actions' | 'engine' >> & { onClose?: ()  => void };

/**
 * Create a message dialog programatically.
 *
 * Example:
 *
 * ```ts
 * const showGeneralMessageDialog = useShowMessageDialog({ text: 'message' });
 * showGeneralMessageDialog();
 *
 * const showMessageDialog = useShowMessageDialog();
 * showMessageDialog({ text: 'custom message', type: 'warning' });
 * ```
 *
 * @param {IShowMessageDialogProps | undefined} props custom props for the dialog to render, by default render a Dialog with type standard
 * @param {string | undefined} id element id to attach the Dialog, by default use the `<MessagesDialogs />` center component.
 * @returns {(renderProps: IShowMessageDialogProps) => void} function to render a Dialog with a content, the renderProps can override the props defined in the hook initialization.
 */
export const useShowMessageDialog = (props: IShowMessageDialogProps = {}, id = 'dialogs-message-id') =>
{
    const { t, ready } = useTranslation('/General', { useSuspense: false });
    /**
     * Show the message dialog with a custom content.
     * @param renderProps override the Hooks props with custom props.
     */
    return (renderProps: IShowMessageDialogProps) =>
    {
        const wrapper = document.getElementById(id);

        if (wrapper && ready)
        {
            const InnerMessageDialog = () =>
            {
                const engine = useDialogEngine(true);

                const finalProps = {
                    text: '',
                    engine: engine,
                    ...props,
                    ...renderProps
                };

                return (
                    <MessageDialog {...finalProps}
                        actions={
                            <>
                                <Button themeColor="primary" onClick={() =>
                                {
                                    engine.hide();
                                    finalProps.onClose?.();
                                }} type="button">
                                    { t('/General:confirmation.accept') }
                                </Button>
                            </>}
                        text={finalProps.text} />
                );
            };

            render(<InnerMessageDialog />, wrapper);
        }
        else
        {
            if (process.env.NODE_ENV === 'development')
            {
                console.warn(`The <MessagesDialogs id=${id} /> element hasn't been found`);
            }
        }
    };
};

/**
 * Creates a 'unavailable feature' message dialog.
 * @returns function to render the message dialog.
 */
export const useShowUnavailableFeatureDialog = () =>
{
    const { t } = useTranslation('/General');

    const showMessageDialog = useShowMessageDialog({
        title: 'TMS',
        width: 'small',
        height: 'small',
        text: [t('/General:unavailableFeature1'), t('/General:unavailableFeature2')]
    });

    return () => showMessageDialog({});
};

// #endregion

// #region Confirmation Dialog

export type IShowConfirmationDialogProps = Partial<Omit<IDialogProps, 'actions' | 'children' | 'appendTo' | 'engine' | 'width' | 'height' | 'onClose'>>  & {
    /**
     * Text message.
     */
    text?: string | string[];
    /**
     * Confirmation button types. By default, 'yes|no'.
     */
    type?: 'accept|cancel' | 'yes|no';
    /**
     * Confirmation button style. By default, 'primary'.
     */
    confirmButtonClass?: 'primary' | 'standard' | 'danger';
    /**
     * Confirmation event handler.
     */
    onConfirm?: ()  => (void | Promise<void>);
    /**
     * Cancel event handler.
     */
    onCancel?: ()  => (void | Promise<void>);
};

/**
 * Create a confirmation dialog programatically.
 *
 * Example:
 *
 * ```ts
 * const showConfiguredConfirmationDialog = useShowConfirmationDialog({ text: 'Show an alert?', onConfirm: () => alert('Hello!') });
 * showConfiguredConfirmationDialog();
 *
 * const showConfirmationDialog = useShowConfirmationDialog();
 * showConfirmationDialog({ text: 'This will destroy the world. Do you want to continue?', confirmButtonType: 'danger', onConfirm: () => alert('BOOM!') });
 * ```
 *
 * @param {IShowConfirmationDialogProps} props custom props for the dialog to render.
 * @param {string | undefined} id element id to attach the Dialog, by default use the `<ConfirmationDialogs />` center component.
 * @returns {(renderProps: IShowConfirmationDialogProps) => void} function to render a Dialog with a content, the renderProps can override the props defined in the hook initialization.
 */
export const useShowConfirmationDialog = (props: IShowConfirmationDialogProps = {}, id = 'dialogs-confirmation-id') =>
{
    const { t, ready } = useTranslation('/General', { useSuspense: false });

    return (renderProps: IShowConfirmationDialogProps) =>
    {
        const wrapper = document.getElementById(id);

        if (wrapper && ready)
        {
            const InnerDialog = () =>
            {
                const engine = useDialogEngine(true);

                const {
                    title = 'TMS',
                    text,
                    type = 'yes|no',
                    confirmButtonClass = 'primary',
                    onConfirm,
                    onCancel,
                    ...restFinalProps
                } = { ...props, ...renderProps };

                const _onConfirm = async () =>
                {
                    onConfirm && await onConfirm();

                    engine.hide();
                };

                const _onCancel = async () =>
                {
                    onCancel && await onCancel();

                    engine.hide();
                };

                const _messages = Array.isArray(text) ?
                    text
                    : (text !== '' ?
                        [text]
                        : []);

                const confirmBtnLabel = type === 'accept|cancel' ?
                    t('/General:confirmation.accept')
                    : t('/General:confirmation.yes');

                const cancelBtnLabel = type === 'accept|cancel' ?
                    t('/General:confirmation.cancel')
                    : t('/General:confirmation.no');

                const commonStyling: CSSProperties = { minWidth: '4rem' };

                return (
                    <Dialog
                        title={title}
                        width={'small'}
                        height={_messages.length > 5 ? 'medium' : 'small'}
                        engine={engine}
                        {...restFinalProps}
                        actions={
                            <>
                                <Button
                                    {...confirmButtonClass === 'primary' && { themeColor: 'primary' }}
                                    {...confirmButtonClass === 'danger' && { themeColor: 'error' }}
                                    style={commonStyling}
                                    onClick={_onConfirm}>
                                    {confirmBtnLabel}
                                </Button>
                                <Button
                                    style={commonStyling}
                                    onClick={_onCancel}>
                                    {cancelBtnLabel}
                                </Button>
                            </>
                        }>
                        {
                            _messages.map((element, index) => (
                                <p key={index}>
                                    {element}
                                </p>
                            ))
                        }
                    </Dialog>
                );
            };

            render(<InnerDialog />, wrapper);
        }
        else
        {
            if (process.env.NODE_ENV === 'development')
            {
                console.warn(`The <ConfirmationDialogs id=${id} /> element hasn't been found`);
            }
        }
    };
};
// #endregion