import { ComponentType, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { TransactionPermissionDTO } from 'Services/Transaction/ITransactionService';

import { useGet } from 'Components/Core/Hooks/GetHook';
import { useLoader } from 'Components/Core/Loaders/LoaderHook';
import { useTransactionService } from 'Components/Core/Services/Hooks/TransactionHook';

import { TransactionPermissionContext, TransactionPermissionProvider } from '../TransactionPermissionProvider';
import { TransactionContext } from '../TransactionProvider';
// #region  Active transaction
/**
 * Get the active transaction.
 * @returns {TransactionContext}
 */
export const useActiveTransaction = () => useContext(TransactionContext);
// #endregion
// #region Transaction permissions
/**
 * Check the permission for the transactionId and set the permissions in the context.
 * @param transactionId Transaction identifier.
 */
export const withPermission = (transactionId: string) => <T extends {}>(Component: ComponentType<T>) => (props: T) =>
{
    const transactionService = useTransactionService(transactionId);

    const [transactionPermission, setTransactionPermission] = useState<TransactionPermissionDTO>({
        read: false,
        modify: false,
        allowedTransactionOperationsKeys:[],
        allowAllTransactionOperations: false
    });

    const loader = useLoader();
    const [loading, setLoading] = useState<boolean>(true);

    useGet(async (mount) =>
    {
        loader.showLoader();
        const userPermission = await transactionService.getPermission();
        if (mount.current)
        {
            setTransactionPermission(userPermission);
            setLoading(false);
        }
        loader.hideLoader();
        return () =>
        {
            // When unmounting, do something?
        };
    });

    return (
        <TransactionPermissionProvider {...transactionPermission}>
            {!loading &&
            <>
                {(transactionPermission.read || transactionPermission.modify) &&
                    <Component {...props}/>
                }
                {(!transactionPermission.read && !transactionPermission.modify) &&
                    <Redirect to="/I000404" />
                }
            </>
            }
        </TransactionPermissionProvider>
    );
};
/**
 * Get the permission for the current transaction.
 * @returns {TransactionPermissionDTO}
 */
export const useTransactionPermission = () =>
{
    let permission = useContext(TransactionPermissionContext);
    // If the permission is null, it's because the withPermission hook isn't being used.
    // Therefore, there isn't a TransactionPermissionContext so we by-pass it.
    if (permission === null)
    {
        permission = {
            read: true,
            modify: true,
            allowedTransactionOperationsKeys: [],
            allowAllTransactionOperations: true
        };
    }
    return permission;
};
// #endregion