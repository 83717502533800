import { forEach, groupBy, sortBy, sum } from 'lodash';
import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { GraficaLicenciaDTO, LicenciaDTO } from './I000000DTO';
import { DonutChartWidget } from './Widgets/DonutChartWidgetComponent';

interface ILicenciasChartProps
{
    licencias: Array<LicenciaDTO>;
}

export const LicenciasChart: VoidFunctionComponent<ILicenciasChartProps> = ({ licencias }) =>
{
    const { t } = useTranslation(['/FMT/I/I00/I000000/I000000'], { useSuspense: false });
    const navigate = useNavigate();

    const datosGrafica: Array<GraficaLicenciaDTO> = [];

    forEach(groupBy(sortBy(licencias, e => e.licenciaLibre), (e) => e.licenciaLibre), (elementos, licenciaLibre) =>
    {
        datosGrafica.push({
            denominacionTipo: licenciaLibre === 'false' ?
                t('widgetLicencias.libres')
                : t('widgetLicencias.activas'),
            numeroElementos: elementos.length,
            color: licenciaLibre === 'false' ?
                '#53C700'
                : '#C7001E'
        });
    });

    const donutCenterRender = () => (
        <>
            <h5 className='totalLicencias'>
                {sum(datosGrafica.map(e => e.numeroElementos))}
            </h5>
            <p>Total</p>
        </>
    );

    // Encontrar el número de licencias libres
    const licenciasLibres = datosGrafica.find(e => e.denominacionTipo === t('widgetLicencias.libres'))?.numeroElementos ?? 0;

    return (
        <DonutChartWidget<GraficaLicenciaDTO>
            title={t('widgetLicencias.titulo')}
            className='licencias'
            data={datosGrafica}
            categoryField='denominacionTipo'
            field='numeroElementos'
            donutCenterRender={donutCenterRender}
            onClick={() => navigate('/G010000')}>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetLicencias.activas'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetLicencias.activas')} </p>
            <p className='contador'>
                {licenciasLibres}
            </p>
            <p className='titulo'>{t('widgetLicencias.libres')}</p>
        </DonutChartWidget>
    );
};
LicenciasChart.displayName = 'LicenciasChart';
