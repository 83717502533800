import { FunctionComponent, createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../Hooks/LayoutHook';

import { ITransactionContext, ITransactionContextProps } from './ITransactionProviderProps';

export const TransactionContext = createContext<ITransactionContext>({
    activeTransaction: {
        id:'',
        title: '...'
    },
    setActiveTransaction: (transaction: ITransactionContextProps | ((prevState: ITransactionContextProps) => ITransactionContextProps)) => {}
});

export const TransactionProvider: FunctionComponent = ({ children }) =>
{
    const { t } = useTranslation();

    const [activeTransaction, setActiveTransaction] = useState<ITransactionContextProps>({
        id: '',
        title: t('loading')
    });

    useDocumentTitle(activeTransaction);

    return (
        <TransactionContext.Provider value={{ activeTransaction, setActiveTransaction }}>
            { children }
        </TransactionContext.Provider>
    );
};

TransactionProvider.displayName = 'TransactionProvider';