import { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';

import { useAuthenticationService } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { IPublicRouteProps } from './IPublicRouteProps';

/**
 * Public route component.
 *
 * If it's mandatory to be logged and there's a logged user, we redirect home.
 * In other case, the component is rendered.
 *
 * @param {RouteProps} props
 */
export const PublicRoute: FunctionComponent<IPublicRouteProps> = ({ onlyNotLogged = false, component: Component, children, ...rest }: IPublicRouteProps) =>
{
    const authenticationService = useAuthenticationService();

    const renderIfNoLogged = (props: RouteComponentProps<any>) =>
    {
        if (onlyNotLogged)
        {
            const isLoggedIn = authenticationService.isLogged();
            const isTokenExpired = authenticationService.isCurrentTokenExpired();

            if (isLoggedIn && !isTokenExpired)
            {
                if (process.env.NODE_ENV === 'development')
                {
                    console.log('Logged user. Redirecting home.');
                }
                return (
                    <Redirect
                        push // We dont want to reload!
                        to={{
                            pathname: '/',
                        }}
                    />
                );
            }
        }

        return Component ? <Component {...props} /> : children;
    };
    return (
        <Route
            {...rest}
            render={renderIfNoLogged}
        />
    );
};