import { FunctionComponent } from 'react';

import { C00Router } from './C00/C00Router';
import { C01Router } from './C01/C01Router';

export const CRouter: FunctionComponent = () =>
{
    return (
        <>
            <C00Router />
            <C01Router />
        </>

    );
};

CRouter.displayName = 'CRouter';