import Module from 'module';

import { FunctionComponent } from 'react';
import React from 'react';

import { withTranslation } from 'Libraries/i18next/I18nextConfig';

import { PrivateRoute } from 'Components/Core/Routes/PrivateRoute';

import { ITransactionRouterProps } from './IRouterProps';

/**
 * Generate a lazy component for the context param.
 *
 * The context param must be created with the `require.context` function.
 *
 * ```ts
 * const context = require.context('./', true, /([a-zA-Z][0-9]{6,6}\.tsx)$/, 'lazy');
 * const TransactionRouter = generateRouter(context, 'G00');
 * ```
 *
 * @param {__WebpackModuleApi.RequireContext} context a require context with the transactions to import.
 * @param {string} moduleName the FMT  module name for the context.
 * @returns {FunctionComponent<ITransactionRouterProps>}
 */
export const generateRouter = (context: __WebpackModuleApi.RequireContext, moduleName: string) =>
{
    const Transactions = React.lazy<FunctionComponent<ITransactionRouterProps>>(async () =>
    {
        const transactionExports = await importTransactions(context);

        return {
            default: ({ exclude = [] }) =>
            {
                const result = transactionExports.filter(([name, component]) => !exclude.find(exc => name === exc));

                return (
                    <>
                        {
                            result.map(([name, component], index) =>
                            {
                                return <PrivateRoute key={`${name}_${index}`} path={`/${name}/:codigo?`} component={withTranslation(component)} />;
                            })
                        }
                    </>
                );
            }
        };
    });

    return (props: ITransactionRouterProps) => <PrivateRoute path={`/${moduleName}([0-9]{4})/:codigo?`}><Transactions {...props } /></PrivateRoute>;
};

/**
 * Load all required transactions components from the RequireContext.
 *
 * Filter the exported code from the RequireContext to only get the transactions component.
 *
 * @param {__WebpackModuleApi.RequireContext} context
 * @returns {Promise<Array<[string, any]>>} The array contains two elements, the first is the name and the seconds is the component.
 */
const importTransactions = async (context: __WebpackModuleApi.RequireContext): Promise<Array<[string, any]>> =>
{
    const modules = context.keys().map(context) as Array<Module | Promise<Module>>;
    const modulesFiltered = modules.map(async (module) =>
    {
        const moduleResolve =  await Promise.resolve(module);
        return Object.entries(moduleResolve).filter(([name, ]) => /^[a-zA-Z][0-9]{6,6}$/.test(name));
    });
    const resultPromise = await Promise.all(modulesFiltered);
    const result = resultPromise.flatMap(e => e);
    // Remove duplicates >=> https://github.com/webpack/webpack/issues/12087
    return result.filter((e, index) =>
        result.findIndex(b => b[0] === e[0]) === index );
};