// Create mi propio sasss para sobreescribir las variables e importar estos archivos ahi...
import 'Theme/Theme.scss';
import { Suspense, StrictMode } from 'react';
import { render } from 'react-dom';
import { Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

// Import i18n (needs to be bundled ;))

import { withTranslation } from 'Libraries/i18next/I18nextConfig';
import 'Libraries/i18next/I18nextConfig';
import 'Libraries/yup/yupConfig';

import { FallbackLayout } from 'Components/Core/Fallback/FallbackLayoutComponent';
import { Intli18nProvider } from 'Components/Core/Layout/Intli18nProvider';
import { Layout } from 'Components/Core/Layout/LayoutComponent';
import { PrivateRoute } from 'Components/Core/Routes/PrivateRoute';
import { PublicRoute } from 'Components/Core/Routes/PublicRoute';
import { ServiceProvider } from 'Components/Core/Services/ServiceProvider';
import { CRouter } from 'Components/FMT/C/CRouter';
import { FRouter } from 'Components/FMT/F/FRouter';
import { GRouter } from 'Components/FMT/G/GRouter';
import { I010000 } from 'Components/FMT/I/I01/I010000/I010000';
import { IRouter } from 'Components/FMT/I/IRouter';
import { RRouter } from 'Components/FMT/R/RRouter';
import { SRouter } from 'Components/FMT/S/SRouter';
import { VRouter } from 'Components/FMT/V/VRouter';

import * as serviceWorker from './serviceWorker';

export const App: React.FunctionComponent = () =>
{
    return (
        <ServiceProvider>
            <Suspense fallback={<FallbackLayout />}>
                <Intli18nProvider>
                    <Switch>
                        <PublicRoute onlyNotLogged path='/login' exact component={withTranslation(I010000)} />
                        <PrivateRoute path='/'>
                            <Layout>
                                <CRouter/>
                                <FRouter/>
                                <GRouter/>
                                <IRouter/>
                                <RRouter/>
                                <SRouter/>
                                <VRouter/>
                            </Layout>
                        </PrivateRoute>
                    </Switch>
                </Intli18nProvider>
            </Suspense>
        </ServiceProvider>
    );
};

App.displayName = 'App';

// Starter
render(
    <StrictMode>
        <BrowserRouter basename="/">
            <App />
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root-tms'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.REACT_APP_SERVICE_WORKERS === 'true')
{
    serviceWorker.register();
}
else
{
    console.log('Service workers disabled!');
}
