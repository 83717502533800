import { uniqueId } from 'lodash';
import { createContext, FunctionComponent } from 'react';

import { useConstant } from '../Hooks/ConstantHook';

export const NotificationsContext = createContext<string>('notifications-id');

export const NotificationsProvider: FunctionComponent = ({ children }) =>
{
    const notificationsId = useConstant(() => `notification-${uniqueId()}`);

    return (
        <NotificationsContext.Provider value={notificationsId}>
            { children }
        </NotificationsContext.Provider>
    );
};