import { Fade } from '@progress/kendo-react-animation';
import { AnimationEventArguments } from '@progress/kendo-react-animation/dist/npm/AnimationInterface';
import { NotificationGroup, Notification as NotificationKendo } from '@progress/kendo-react-notification';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { PropsWithChildren, ReactElement } from 'react';

import { INotificationProps } from './INotificationProps';
import { NotificationsContext } from './NotificationsProvider';

import './NotificationTheme.scss';

/**
 * Notication center where all notifications are added.
 * Must be used inside a `<NotificationProvider/>`
 */
export function Notifications(): ReactElement<{id?: string}>
{
    const notificationId = useContext(NotificationsContext);
    return (
        <div id={notificationId} ></div>
    );
}

/**
 * Notification component.
 * @param props
 */
export function Notification({
    className,
    style,
    type = { style: 'success', icon: true },
    onExited = () => {},
    children
}: PropsWithChildren<INotificationProps>): ReactElement<PropsWithChildren<INotificationProps>>
{
    const [show, setShow] = useState<boolean>(true);

    const _children = show ? (
        <NotificationKendo
            {...{ className, style, type }}>
            {children}
        </NotificationKendo>
    ) : null;

    const _onExited = (event: AnimationEventArguments) => { onExited(event); };

    useEffect(() =>
    {
        const timeoutId = setTimeout(() =>
        {
            setShow(false);
        }, 2500);

        return () =>
        {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <NotificationGroup
            className={`notification ${(children === undefined) && 'noContent'}`}>
            <Fade appear unmountOnExit transitionEnterDuration={250} transitionExitDuration={250} onExited={_onExited}>
                {_children}
            </Fade>
        </NotificationGroup>
    );
};