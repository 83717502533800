import _ from 'lodash';
import { useHistory } from 'react-router-dom';

/**
 * Migration hook.
 * Use the new react-router v6 API with the react-router v5
 * @see https://reactrouter.com/docs/en/v6/api#usenavigate
 */
export const useNavigate = () =>
{
    const history = useHistory();
    return (location: string | number, opts?: { replace: boolean}) =>
    {
        if (_.isNumber(location))
        {
            if (location < 0)
            {
                history.goBack();
            }
            else
            {
                history.goForward();
            }
        }
        else
        {
            if (opts?.replace)
            {
                history.replace(location);
            }
            else
            {
                history.push(location);
            }
        }
    };
};

/**
 * Migration hook.
 * Use the new react-router v6 API with the react-router v5
 * @see https://reactrouter.com/docs/en/v6/api#usenavigationtype
 */
export const useNavigationType = () =>
{
    const { action } = useHistory();
    return action;
};