import { ResponseDTO, ResponseStatusEnum } from 'Services/DTOs/IResponse';
import { appendQueryParams, isFormData } from 'Services/Utils/Utils';

import { IFetchAuthService } from './IFetchAuthService';
import { FormBody, IHttpService, QueryParams } from './IHttpService';
import { JsonFormatter } from './JsonFormatter';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export class HttpServiceImpl implements IHttpService
{
    private jsonFormatter = new JsonFormatter();
    private fetchAuthService: IFetchAuthService;

    constructor(fetchAuthService: IFetchAuthService)
    {
        this.fetchAuthService = fetchAuthService;
    }

    async get<T extends ResponseDTO<U>, U = any>(url: string, queryParams?: QueryParams): Promise<T>
    {
        const urlFinal = appendQueryParams( `${baseUrl}${url}`, queryParams);

        const init : RequestInit = { method: 'GET', headers: { 'accept': 'application/json' } };

        const response = await this.fetchAuthService.fetch(urlFinal, init);
        const json = await response.json() as T;

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        if (json.error)
        {
            throw new Error(json.error.message);
        }

        this.jsonFormatter.format(json.data);

        return json;
    }

    async post<T extends ResponseDTO<U>, U = any>(url: string, body: FormData): Promise<T>
    async post<T extends ResponseDTO<U>, U = any>(url: string, body?: FormBody, queryParams?: QueryParams): Promise<T>
    {
        let urlFinal;
        let init : RequestInit;

        if (body && isFormData(body))
        {
            urlFinal = `${baseUrl}${url}`;

            init = { method: 'POST', body: body };
        }
        else
        {
            urlFinal = appendQueryParams(`${baseUrl}${url}`, queryParams);

            init = { method: 'POST', body: body ? JSON.stringify(body) : undefined, headers: { 'accept': 'application/json', 'content-type': 'application/json' } };
        }

        const response = await this.fetchAuthService.fetch(urlFinal, init);
        const json = await response.json();

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        this.jsonFormatter.format(json.data);

        return json;
    }

    async put<T extends ResponseDTO<U>, U = any>(url: string, body?: FormBody, queryParams?: QueryParams): Promise<T>
    {
        const urlFinal = appendQueryParams( `${baseUrl}${url}`, queryParams);

        const init : RequestInit = { method: 'PUT', body: body ? JSON.stringify(body) : undefined, headers: { 'accept': 'application/json', 'content-type': 'application/json' } };

        const response = await this.fetchAuthService.fetch(urlFinal, init);
        const json = await response.json() as T;

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        this.jsonFormatter.format(json.data);

        return json;
    }

    async delete<T extends ResponseDTO<U>, U = any>(url: string, queryParams?: QueryParams): Promise<T>
    {
        const urlFinal = appendQueryParams( `${baseUrl}${url}`, queryParams);

        const init : RequestInit = { method: 'DELETE', headers: { 'accept': 'application/json' } };

        const response = await this.fetchAuthService.fetch(urlFinal, init);
        const json = await response.json() as T;

        if (json.status === ResponseStatusEnum.Error)
        {
            throw new Error(json.error?.message);
        }

        if (json.error)
        {
            throw new Error(json.error.message);
        }

        this.jsonFormatter.format(json.data);

        return json;
    }

    // #endregion
}