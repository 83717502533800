import { CoreValorResultadoEnum } from 'Services/Enum/CoreValorResultadoEnum';

export interface ResponseDTO<T>  extends CoreResultDTO
{
    status: ResponseStatusEnum;
    data: T;
    error?: ResponseErrorDTO;
}

export interface CoreResultDTO
{
    /**
     * CoreValorResultadoEnum
     * OK = 1,
     * KO = 2,
     * SI = 3,
     * NO = 4,
     * No_Aplica = 5,
     * No_Requerido = 6
     */
    result:  CoreValorResultadoEnum;
    errorDescription?: string;
}

export interface CRUDResponseDTO<T, Y = string | number> extends ResponseDTO<T>
{
    /**
     * CRUDOperationEnum
     * 1: Create
     * 2: Read
     * 3: Update
     * 4: Delete
     */
    CRUDOperation: CRUDOperationEnum;
    code: Y;
}

export interface DataResponseDTO<T, Y = string | number> extends ResponseDTO<T>
{
    code: Y;
}

export interface ReadResponseDTO<T> extends CRUDResponseDTO<T>
{
    /**
     * 2: Read
     */
    CRUDOperation: CRUDOperationEnum.Read;
}

export interface UpdateResponseDTO<T> extends CRUDResponseDTO<T>
{
    /**
     * 3: Update
     */
    CRUDOperation: CRUDOperationEnum.Update;
}

export enum ResponseStatusEnum
{
    Success = 1,
    Error = 2,
}

export interface ResponseErrorDTO
{
    errorCode: number;
    message: string;
}

export enum CRUDOperationEnum {
    Create = 1,
    Read = 2,
    Update = 3,
    Delete = 4
}

export interface EntityCoreDTO
{
    code: string;
}