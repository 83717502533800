import { isString } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { useConstant } from 'Components/Core/Hooks/ConstantHook';

import { BlockedGetterContext, BlockedSetterContext } from '../BlockedProvider';
import { ITransactionProps } from '../Transaction/ITransactionProps';

/**
 * Gets the TMS app root html element as a constant.
 * @returns HtmlElement
 */
export const useRootElement = () =>
{
    const rootElement = useConstant(() => document.getElementById('root-tms') || document.body);

    return rootElement;
};

/**
 * Hook for changing the page title from a string or a TransactionProps object.
 * @param transaccion
 */
export function useDocumentTitle(titleOrTransaction: string | ITransactionProps): void
{
    useEffect(() =>
    {
        let documentTitle = '';

        if (isString(titleOrTransaction))
        {
            documentTitle = `${titleOrTransaction} - `;
        }
        else
        {
            if (titleOrTransaction.title !== '')
            {
                documentTitle += `${titleOrTransaction.title} - `;
            }

            if (titleOrTransaction.id !== '')
            {
                documentTitle += `${titleOrTransaction.id} - `;
            }
        }

        document.title = `${documentTitle}TMS`;
    }, [titleOrTransaction]);
};

// #region Media queries hooks

/**
 * Hook indicating if app is running from a touch device.
 * Inspired by https://medium.com/swlh/using-window-matchmedia-for-media-queries-in-reactjs-97ddc66fca2e
 * and https://css-tricks.com/interaction-media-features-and-their-potential-for-incorrect-assumptions/
 * @returns {boolean} true if is a touch device. false in other case.
 */
export const useTouchDevice = () =>
{
    const queryString = '(pointer: coarse) and (hover: none)';

    const [touchDevice, setTouchDevice] = useState(window.matchMedia(queryString).matches);

    useEffect(() =>
    {
        const mediaQuery = window.matchMedia(queryString);

        const checkTouchDevice = (ev: MediaQueryListEvent) =>
        {
            setTouchDevice(ev.matches);
        };

        mediaQuery.addEventListener('change', checkTouchDevice);

        return () =>
        {
            mediaQuery.removeEventListener('change', checkTouchDevice);
        };
    }, []);

    return touchDevice;
};

type BootstrapSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * Hook indicating if app window is below Bootstrap size breakpoint.
 * @param {BootstrapSize} size
 * @returns {boolean} true if is below the breakpoint. false in other case.
 */
export const useMediaBreakpointDown = (size: BootstrapSize) =>
{
    const queryString: string = `(max-width: ${size === 'sm' ?
        '575.98'
        : (size === 'md' ?
            '767.98'
            : (size === 'lg' ?
                '991.98'
                : (size === 'xl' ?
                    '1199.98'
                    : '0' )))}px)`;

    const [deviceSize, setDeviceSize] = useState<boolean>(window.matchMedia(queryString).matches);

    useEffect(() =>
    {
        const mediaQuery = window.matchMedia(queryString);

        const onChangeDeviceSize = (event: MediaQueryListEvent) =>
        {
            setDeviceSize(event.matches);
        };

        mediaQuery.addEventListener('change', onChangeDeviceSize);

        return () =>
        {
            mediaQuery.removeEventListener('change', onChangeDeviceSize);
        };
    }, [queryString]);

    return deviceSize;
};

// #endregion

// #region Blocked layout hooks

/**
 * Hook for getting the blocked state.
 * @returns {boolean}
 */
export const useBlocked = () =>
{
    return useContext(BlockedGetterContext);
};

/**
 * Hook for setting the blocked state.
 * @returns  {((blocked: boolean) => void)} setting function
 */
export const useBlockedSetter = () =>
{
    return useContext(BlockedSetterContext);
};

// #endregion