import _ from 'lodash';
import { isSchema, SchemaOf } from 'yup';

import { QueryParams } from 'Services/Backend/IHttpService';

// #region Typeguards
export function hasCodigo<T>(param: any): param is T & { codigo: string | number }
{
    return param.codigo !== undefined && param.codigo !== null;
}

export function hasCode<T>(param: any): param is T & { code: string | number }
{
    return param.code !== undefined && param.code !== null;
}

export function isCodigoNull<T>(param: any): param is T & { codigo: null }
{
    return param.codigo === null;
}

/**
 * Check if the param is a `SchemaOf<Array<T>>`
 * @param param - any schema
 * @returns
 */
export function isArraySchemaOf<T>(param: any): param is SchemaOf<Array<T>>
{
    const schema = param as SchemaOf<Array<T>> | undefined;
    return isSchema(schema) && schema?.describe().type === 'array' && schema?.innerType !== undefined;
}

/**
 * Check if the param is a `Date`
 * @param param any
 */
export function isDate(param: any): param is Date
{
    return (
        param instanceof Date ||
        (typeof param === 'object' &&
            Object.prototype.toString.call(param) === '[object Date]')
    );
}

/**
 * Check if the param is a `number`
 * @param param any
 */
export function isNumber(param: any): param is number
{
    return _.isFinite(param);
}

/**
 * Type guard for any key, `k`.
 * Marks `k` as a key of `T` if `k` is in `obj`.
 * @param obj object to query for key `k`
 * @param k key to check existence in `obj`
 */
export function isKeyOf<T extends object>(obj: T, k: keyof any): k is keyof T
{
    return k in obj;
}

/**
 * Check if the param is a `FormData`
 * @param param any
 */
export function isFormData(param: any): param is FormData
{
    return (
        param instanceof FormData ||
        (typeof param === 'object' &&
            Object.prototype.toString.call(param) === '[object FormData]')
    );
}
// #endregion

// #region String
/**
 * Converts the first character of string to upper case and the remaining to lower case.
 */
export function capitalize<T extends string = string>(s: T): Capitalize<T>
{
    return s.charAt(0).toUpperCase() + s.slice(1) as Capitalize<T>;
}
// #endregion

// #region Array
/**
 * Move an element in the array.
 * Keep the others elements in the same position.
 * @param {Array<T>} arr the array to reorder
 * @param {number} oldIndex the index to move to another position.
 * If the index is greater than the length of the array, this method do nothing.
 * @param {number} newIndex the target index of the element.
 * If the index is greater than the length of the array, the element will be moved to the last position of the array.
 *
 * @return new array with the new elements reordered
 */
export function move<T>(arr: Array<T>, oldIndex: number, newIndex: number): Array<T>
{
    if (oldIndex < 0 || oldIndex >= arr.length)
    {
        return arr;
    }

    if (newIndex >= arr.length)
    {
        newIndex = arr.length - 1;
    }

    if (newIndex < 0)
    {
        newIndex = 0;
    }

    const result = [...arr];

    result.splice(newIndex, 0, result.splice(oldIndex, 1)[0]);

    return result;
};
// #endregion

// #region Color
/**
 * Converts a hexadecimal color into the rgba format.
 * @link https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
 * @param hexCode
 * @param opacity
 * @returns
 */
export const convertHexToRGBA = (hexCode: string, opacity = 1): string =>
{
    let hex = hexCode.replace('#', '');

    if (hex.length === 3)
    {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100)
    {
        opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
};
// #endregion

// #region Http utils

/**
 * Get the url with the query params.
 *
 * @param {string} url the base url, with or without any query parameters.
 * @param {QueryParams} queryParams object with the params to append to the url
 * @returns {string} the complete url with all the query parameters.
 */
export const appendQueryParams = (url: string, queryParams?: QueryParams): string =>
{
    if (!queryParams)
    {
        return url;
    }
    else
    {
        const appendInit = url.indexOf('?') !== -1 ? '&' : '?';
        const queryString = Object.entries(queryParams).reduce((prev, [key, value]) =>
        {
            if (!prev)
            {
                return `${key}=${value}`;
            }
            else
            {
                return `${prev}&${key}=${value}`;
            }
        }, '');
        return `${url}${appendInit}${queryString}`;
    }
};

// #endregion