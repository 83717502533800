import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { IconSolid } from 'Theme/Icons';
import { FunctionComponent, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Alert } from 'Components/Core/Alert/AlertComponent';
import { DivRow } from 'Components/Core/Layout/DivComponent';
import { useAuthenticationService } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { I2FAFormProps } from './ILoginFormProps';

interface IOTPData {
    otpCodigo: string;
}

export const TwoFactorAuthForm : FunctionComponent<I2FAFormProps> = ({
    className,
    token,
    onSuccess = () => null,
}) =>
{
    const { t } = useTranslation('/FMT/I/I01/I010000/I010000');
    const [deshabilitarForm, setDeshabilitarForm] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const authenticationService = useAuthenticationService();

    // Evento de envío del código 2FA
    const onSubmitLogin2FAForm = async ({ otpCodigo } : IOTPData) =>
    {
        if (token)
        {
            setDeshabilitarForm(true);

            try
            {
                const resultado = await authenticationService.twoFactor(otpCodigo, token);

                onSuccess(resultado);
            }
            catch (e)
            {
                setError(true);
                setDeshabilitarForm(false);
            }
        }
    };

    return (
        <Form
            onSubmit={onSubmitLogin2FAForm}
            render={({ handleSubmit }) => (
                <form id="Login2FAForm" className={className} onSubmit={handleSubmit}>
                    <DivRow>
                        <Field
                            name="otpCodigo"
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    className="form-control"
                                    id="2facodigo"
                                    type="text"
                                    label={t('otp.label')}
                                    required={true}
                                    validationMessage={t('otp.mandatory')}
                                    validityStyles={false}
                                    disabled={deshabilitarForm}
                                    autoComplete="off"/>
                            )}/>
                        <div className="btnWrap">
                            <Button disabled={deshabilitarForm} type="submit" themeColor="primary" iconClass={!deshabilitarForm ? IconSolid.arrowRight : ''}>
                                {deshabilitarForm && <Loader type="converging-spinner" size="small" themeColor="light" />}
                                {t('btnSend2FA')}
                            </Button>
                        </div>
                    </DivRow>
                    {error && <Alert type="danger">{t('otp.error')}</Alert>}
                </form>
            )}/>
    );
};

TwoFactorAuthForm.displayName = 'Login2FAForm';