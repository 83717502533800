import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { IconRegular, IconSolid } from 'Theme/Icons';
import { FunctionComponent } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'Components/Core/Button/ButtonComponent';
import { useShowUnavailableFeatureDialog } from 'Components/Core/Dialog/Hooks/DialogHook';
import { useGet } from 'Components/Core/Hooks/GetHook';
import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';
import { useAuthenticationService, useCurrentUser } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { IUserSidebarProps } from './IUserSidebarProps';
import { NotificationPreview } from './NotificationPreviewComponent';

import './UserSidebarTheme.scss';

/**
 * User sidebar panel component.
 * @param props
 * @returns UserSidebar
 */
export const UserSidebar: FunctionComponent<IUserSidebarProps> = ({ open, toggleUserSidebar }) =>
{
    // #region Hooks
    const currentUser = useCurrentUser();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation('/Core/Layout/Layout');
    const showUnavailableFeatureDialog = useShowUnavailableFeatureDialog();
    const authenticationService = useAuthenticationService();
    // #endregion

    // #region Languages
    const languageDropDownRef = useRef<DropDownButton>(null);

    const languageList = [
        {
            text: t('userSidebar.english'),
            value: 'en',
            selected: false
        },
        {
            text: t('userSidebar.spanish'),
            value: 'es',
            selected: false
        }
    ];

    languageList.forEach(e =>
    {
        e.selected = i18n.language.includes(e.value);
    });

    const refreshLanguageButtonTitle = () =>
    {
        if (languageDropDownRef.current && languageDropDownRef.current.element)
        {
            languageDropDownRef.current.element.title = t('userSidebar.language');
        }
    };

    const onLanguageItemClick = (event: DropDownButtonItemClickEvent) =>
    {
        i18n.changeLanguage(event.item.value);
        toggleUserSidebar();
        refreshLanguageButtonTitle();
    };
    // #endregion

    // #region Logout
    const onClickLogOut = () =>
    {
        authenticationService.logout();
        navigate('/login');
    };
    // #endregion

    // #region Render
    const listGroupItemClass: string = 'list-group-item';

    const _notAvailableEvent = () => { toggleUserSidebar(); showUnavailableFeatureDialog(); };

    useGet((mount) =>
    {
        if (mount.current)
        {
            refreshLanguageButtonTitle();
        }
    });

    return (
        <nav id="userSidebar" className={open ? 'active' : ''}>
            <header>
                <div className='d-inline-flex d-sm-none personCompanyName'>
                    <h2 title={currentUser.companyName}>{currentUser.companyName}</h2>
                    <h3 title={currentUser.personName}>{currentUser.personName}</h3>
                </div>
                <div className='backBtn'>
                    <Button iconClass={IconSolid.arrowRight} onClick={toggleUserSidebar} fillMode="flat"/>
                </div>
            </header>
            <main>
                <NotificationPreview toggleUserSidebar={toggleUserSidebar} />
                <ul className="list-group list-group-flush userLinks">
                    <li className={`${listGroupItemClass} tmsConfig`}>
                        <div className="userCalendar">
                            <Button iconClass={IconRegular.calendarAlt} fillMode="flat" themeColor="primary" onClick={_notAvailableEvent} title={t('userSidebar.calendar')} />
                        </div>
                        <div className="languages">
                            <DropDownButton
                                ref={languageDropDownRef}
                                onItemClick={onLanguageItemClick}
                                iconClass={IconSolid.language}
                                items={languageList}
                                text={languageList.find(e => e.selected)?.value.toUpperCase()}
                                popupSettings={{
                                    anchorAlign: { vertical:'top', horizontal:'right' },
                                    popupAlign: { vertical:'bottom', horizontal:'right' },
                                    animate: false,
                                    popupClass: 'languagePopup'
                                }}
                                themeColor="primary"
                                fillMode="flat"/>
                        </div>
                        <div className="userConfig">
                            <Button iconClass={IconSolid.userGear} fillMode="flat" themeColor="primary" onClick={_notAvailableEvent} title={t('userSidebar.configuration')} />
                        </div>
                    </li>
                    <li className={`${listGroupItemClass} logout`}>
                        <Button iconClass={IconSolid.signOutAlt} fillMode="flat" themeColor="primary" onClick={onClickLogOut} title={t('userSidebar.logout')}>
                            {t('userSidebar.logout')}
                        </Button>
                    </li>
                </ul>
            </main>
        </nav>
    );
    // #endregion
};