import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { WithTranslation, withTranslation as _withTransalation } from 'react-i18next';

import { Backend } from './Backend';

const defaultNS = '/General';

i18n
    // Load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(new Backend())
    // Detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // Pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // Init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        defaultNS: defaultNS,
        // Debug: true,
        interpolation: {
            escapeValue: false, // Not needed for react as it escapes by default
        }
    });

/**
 * Add the translation namespace to the FMT transaction.
 *
 * @param component
 */
export function withTranslation<P extends WithTranslation>(component: React.ComponentType<P>)
{
    if (component.displayName)
    {
        const transaction = component.displayName;
        return _withTransalation(`/FMT/${transaction.slice(0, 1)}/${transaction.slice(0, 3)}/${transaction}/${transaction}`)(component);
    }
    else
    {
        const error = `❌ El componente ${component.name} debe tener displayName para utilizar el HoC \`withTranslation\``;
        console.error(error, component);
        throw new Error(error);
    }
};

export default i18n;