import { IconSolid } from 'Theme/Icons';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'Components/Core/Button/ButtonComponent';
import { useShowUnavailableFeatureDialog } from 'Components/Core/Dialog/Hooks/DialogHook';

import { INotificationPreviewProps } from './INotificationPreviewProps';

import './NotificationPreviewTheme.scss';

/**
 * Notification previews component.
 * @param {INotificationPreviewProps} props
 * @returns NotificationPreview
 */
export const NotificationPreview: FunctionComponent<INotificationPreviewProps> = ({ toggleUserSidebar }: INotificationPreviewProps) =>
{
    // #region Hooks
    const { t } = useTranslation('/Core/Layout/Layout');
    const showUnavailableFeatureDialog = useShowUnavailableFeatureDialog();
    // #endregion

    // #region Last messages
    const onClickMessageCenterBtn = () =>
    {
        toggleUserSidebar();
        showUnavailableFeatureDialog();
    };
    // #endregion

    // #region Render
    return (
        <div id="notificationPreview">
            <div className="title">
                <h3>
                    {t('userSidebar.lastMessages')}
                </h3>
                <Button
                    iconClass={IconSolid.mailBulk}
                    fillMode="flat"
                    themeColor="primary"
                    title={t('userSidebar.messageCenter')}
                    onClick={onClickMessageCenterBtn} />
            </div>
            <div className="notificationList">
                <p><span className={IconSolid.mailBulk} /></p>
                <p>{t('userSidebar.noMessages')}</p>
            </div>
        </div>
    );
    // #endregion
};