import { IBackendFormService } from 'Services/Backend/IBackendFormService';

import { useConstant } from 'Components/Core/Hooks/ConstantHook';

import { ServicesTypes, useService } from '../ServiceProvider';

/**
 * Gets an instance of `IBackendFormService` customized for a transaction
 * @param transactionId, the transaction controller that will be used
 * @returns `IBackendFormService` instance
 */
export const useBackendFormService = (transactionId: string): IBackendFormService =>
{
    const backendFormService = useService(ServicesTypes.IBackendFormServiceFactory);
    return useConstant(() => backendFormService.get(transactionId));
};