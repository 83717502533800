import { isArray } from 'lodash';
import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useActiveTransaction } from '../../Transaction/Hooks/TransactionHook';

import { useInnerBreadcrumb, useInnerBreadcrumbSetter } from './InnerBreadcrumbHook';

import './BreadcrumbTheme.scss';

interface IBreadcrumbProps
{
    className?: string;
}

/**
 * Breadcrumb component.
 * @param {IBreadcrumbProps} props
 * @returns Breadcrumb
 */
export const Breadcrumb: FunctionComponent<IBreadcrumbProps> = ({ className = '' }: IBreadcrumbProps) =>
{
    // #region Hooks
    const { activeTransaction } = useActiveTransaction();
    const innerBreadcrumb = useInnerBreadcrumb();
    const setInnerBreadcrumb = useInnerBreadcrumbSetter();
    // #endregion

    // #region Navigation history
    const { pathname } = useLocation();
    useEffect(() => setInnerBreadcrumb([]), [pathname, setInnerBreadcrumb]);
    // #endregion

    // #region Render
    const _innerBreadcrumb = isArray(innerBreadcrumb) ?
        innerBreadcrumb
        : (innerBreadcrumb !== '' ?
            [innerBreadcrumb]
            : []);

    return (
        <div className={`${className} transaction`} >
            <div aria-label="breadcrumb">
                <ol className="breadcrumb"
                    title={activeTransaction.id !== '' ? `${activeTransaction.id} - ${activeTransaction.title}` : ''}>
                    {activeTransaction.id !== '' &&
                        <>
                            <li className="breadcrumb-item id">
                                {activeTransaction.title}
                            </li>
                            <li className="breadcrumb-item d-none d-lg-block title">
                                {activeTransaction.id}
                                <>
                                    {_innerBreadcrumb.length > 0 &&
                                        <ol className="innerBreadcrumb" title={_innerBreadcrumb.join(' / ')}>
                                            {
                                                _innerBreadcrumb.map((element, index) =>
                                                {
                                                    return (
                                                        <li key={`innBred_${index}`}
                                                            className="breadcrumb-item d-block">
                                                            {element}
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ol>
                                    }
                                </>
                            </li>
                        </>
                    }
                </ol>
            </div>
        </div>
    );
    // #endregion
};

Breadcrumb.displayName = 'Breadcrumb';