import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip, SharedTooltipContext, TooltipContext } from '@progress/kendo-react-charts';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import 'hammerjs';
import { ReactElement, ReactNode } from 'react';

import './DonutChartWidgetTheme.scss';

export interface ChartElementDTO {
    color: string | null;
}

interface IDonutChartWidgetProps<T extends ChartElementDTO>
{
    title?: string;
    className?: string;
    data: T[]
    categoryField: keyof T;
    field: keyof T;
    /**
     * When set, the prop is used to render the Donut Center template.
     */
    donutCenterRender?: () => ReactElement<any>;
    legend?: boolean;
    children?: ReactNode;
    onClick?: () => void;
}

export function DonutChartWidget<T extends ChartElementDTO>({
    title,
    className = '',
    data,
    categoryField,
    field,
    donutCenterRender,
    legend = false,
    children,
    onClick
}: IDonutChartWidgetProps<T>): ReactElement<IDonutChartWidgetProps<T>>
{
    const sumaTotal = data.reduce((suma, item) => suma + (item[field] as unknown as number), 0);
    const valorPorDefecto = sumaTotal === 0 ? [{ color: '#BDBABB', [field]: 0.1 }] : data;

    const renderTooltip = (context: TooltipContext | SharedTooltipContext) =>
    {
        const { category, value } = (context as TooltipContext).point || context;

        const valorFinal = value === 0.1 ? 0 : value;

        return (
            <div>
                {category}{value !== 0.1 ? `: ${valorFinal}` : `${title}: ${valorFinal}`}
            </div>
        );
    };

    return (
        <Card
            orientation='horizontal'
            className={`donutChartWidget ${onClick ? 'clickable' : ''} ${className}`}
            {...onClick && { onClick }}>
            <CardBody>
                {title && <CardTitle>{title}</CardTitle>}
                <div className={`contentWrapper${children ? ' withAdded' : ''}`}>
                    <div className='chartWrapper'>
                        <Chart donutCenterRender={donutCenterRender} renderAs='svg'>
                            {legend !== true && <ChartTooltip render={renderTooltip}/>}
                            <ChartSeries>
                                <ChartSeriesItem type='donut' autoFit
                                    data={valorPorDefecto}
                                    categoryField={categoryField as string}
                                    field={field as string}
                                    colorField="color" size={20} holeSize={30}/>
                            </ChartSeries>
                            <ChartLegend
                                visible={legend}
                                position='bottom'
                                align='center'
                                orientation='vertical'
                                labels={{
                                    font: 'condensed 0.75rem "Public Sans"'
                                }} />
                        </Chart>
                    </div>
                    {children &&
                    <div className='addedContent'>
                        {children}
                    </div>
                    }
                </div>
            </CardBody>
        </Card>
    );
};

DonutChartWidget.displayName = 'DonutChartWidget';