import { IconRegular } from 'Theme/Icons';
import { FunctionComponent } from 'react';
import { WithTranslation } from 'react-i18next';

import { Transaction } from 'Components/Core/Layout/Transaction/TransactionComponent';

import './I000404Theme.scss';

const transactionId = 'I000404';

export const I000404: FunctionComponent<WithTranslation> = ({ t }: WithTranslation) =>
{
    return (
        <Transaction id={transactionId} title={t('titulo')} cardMode={false}>
            <p>{t('mensaje')}</p>
            <div>
                <span className={`icon ${IconRegular.meh}`}></span>
                <span className={`icon ${IconRegular.mehRollingEyes}`}></span>
                <span className={`icon ${IconRegular.mehBlank}`}></span>
            </div>
            <div>
                <a className="k-button k-button-md k-button-rectangle k-button-link k-button-link-primary k-rounded-md" href="/I000001">
                    <span className="k-button-text">{t('volverMapa')}</span>
                </a>
            </div>
        </Transaction>
    );
};

I000404.displayName = transactionId;