import { IntlProvider, LocalizationProvider, load, loadMessages } from '@progress/kendo-react-intl';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import enKendoMessages from './KendoReactMessages_en.json';
import esKendoMessages from './KendoReactMessages_es.json';

loadMessages(esKendoMessages, 'es');
loadMessages(enKendoMessages, 'en');

load(
    likelySubtags,
    currencyData,
    weekData,
    esNumbers,
    esLocalCurrency,
    esCaGregorian,
    esDateFields
);

/**
 * Internationalization wrapper component.
 * @param props
 * @returns Intli18nProvider
 */
export const Intli18nProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) =>
{
    const { i18n } = useTranslation();

    return (
        <LocalizationProvider language={i18n.language}>
            <IntlProvider locale={i18n.language}>
                {children}
            </IntlProvider>
        </LocalizationProvider>
    );
};