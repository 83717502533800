import { FunctionComponent } from 'react';

import { R00Router } from './R00/R00Router';

export const RRouter: FunctionComponent = () =>
{
    return (
        <>
            <R00Router />
        </>
    );
};

RRouter.displayName = 'RRouter';